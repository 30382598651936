// import axiosapi from "../api/axiosapi";
import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";

const useLogout = () => {
    const { setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axiosPrivate.put('/logout', {
                withCredentials: true
            });
            response.data && alert(response.data.message);
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout;