import React, {useEffect, useRef, useState} from 'react';
import GeoGebra from 'react-geogebra';
import axiosapi from '../api/axiosapi';
import useAuth from "../hooks/useAuth";
// import '../App.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate';

function GeoGebraP(props) {
    const { auth } = useAuth();
    const [answerData, setAnswerData] = useState('');
    const [visitAnswerData, setVisitAnswerData] = useState('');
    const [answerDataResponse, setAnswerDataResponse] = useState('');
    const [visitAnswerDataResponse, setVisitAnswerDataResponse] = useState('');
    const [visitId, setVisitId] = useState();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const saveAnswer = async () => {
            try {
                const response = await axiosPrivate.post('/solve/history/add', answerData,
                    {
                        signal: controller.signal,
                        withCredentials: true
                    }
                )
                isMounted && setAnswerDataResponse(response.data);
                // alert("풀이 내용을 저장했습니다. 마지막으로 풀이한 내용과 제대로 해결한 풀이를 내 풀이 보기로 볼 수 있습니다.");
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        const saveVisitAnswer = async () => {
            try {
                const response1 = await axiosapi.post('/solve/history/visit/add', visitAnswerData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                )
                setVisitAnswerDataResponse(response1.data);
                console.log(response1.data);
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        auth?.accessToken ? (answerData && saveAnswer()) : (visitAnswerData && saveVisitAnswer());

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[answerData])


    let previStrState = '';
    let previStrState1 = '';
    let previStrState2 = '';
    let previStrState3 = '';
    let previStrState4 = '';
    let previTrialno3 = 1;
    let previTrialno1=0;
    let previTrialno2=0;
    let previScore = 0;
    let previScoreG = 0;
    let previScoreText = '';
    
    const trialno1=useRef(0);
    const trialno2=useRef(0);
    const trialno4=useRef(2);
    const trialno5=useRef(0);
    const newObjectNum=useRef(0);
    const newObjectNumWithoutPoint=useRef(0);

    const onLoad = () => {
        const app = window.ggbApplet;
        let obj_no = app.getObjectNumber();

        if (props.problemType === 2) {
            let strName2 = '';
            let strType2 = '';
            let strVal2 = '';
            let strState2Temp = '';
            for (let i=0; i < obj_no; i++) {
                strName2 = app.getObjectName(i);
                strType2 = app.getObjectType(strName2);
                strVal2 = app.getValueString(strName2, false);
                if (strName2 !== 'textConfirm' && strName2 !== 'trialno1' && strName2 !== 'trialno2' && strName2 !== 'trialno3' && strName2 !== 'trialno4') {
                    if (strType2 === 'numeric' || strType2 === 'point') {
                        strState2Temp += strVal2 + "|";
                    }
                    if (strType2 === 'text') {
                        let strVal2T1 = strVal2.replace(/\=/gi, "\=");
                        let strVal2T2 = strVal2T1.replace(/\!/gi, "\!");
                        let strVal2T3 = strVal2T2.replace(/\(/gi, "\(");
                        let strVal2T4 = strVal2T3.replace(/\//gi, "\/");
                        let strVal2T = strVal2T4.replace(/\)/gi, "\)");
                        strState2Temp += strName2 + "= \"" + strVal2T + "\"|";
                    }
                }
            }
            previStrState1 = strState2Temp;
        }

        if (props.problemType === 6) {
            previScore = app.getValue('noScore');
        }

        if (props.problemType === 7) {
            let strName2 = '';
            let strType2 = '';
            let strVal2 = '';
            let strState2Temp = '';
            for (let i=0; i < obj_no; i++) {
                strName2 = app.getObjectName(i);
                strType2 = app.getObjectType(strName2);
                strVal2 = app.getValueString(strName2, false);
                if (strName2.includes("noAn") && strType2 === 'numeric') {
                    strState2Temp += strVal2 + "|";
                }
            }
            previStrState4 = strState2Temp;
        }

        if (props.problemType === 10) {
            let strName2 = '';
            let strType2 = '';
            let strVal2 = '';
            let strState2Temp = '';
            for (let i=0; i < obj_no; i++) {
                strName2 = app.getObjectName(i);
                strType2 = app.getObjectType(strName2);
                strVal2 = app.getValueString(strName2, false);
                if (strName2 !== 'textConfirm' && strName2 !== 'trialno1' && strName2 !== 'trialno2' && strName2 !== 'trialno3' && strName2 !== 'trialno4') {
                    if (strType2 === 'numeric' || strType2 === 'point' || strType2 === 'list') {
                        strState2Temp += strVal2 + "|";
                    }
                    if (strType2 === 'text') {
                        let strVal2T1 = strVal2.replace(/\=/gi, "\=");
                        let strVal2T2 = strVal2T1.replace(/\!/gi, "\!");
                        let strVal2T3 = strVal2T2.replace(/\(/gi, "\(");
                        let strVal2T4 = strVal2T3.replace(/\//gi, "\/");
                        let strVal2T = strVal2T4.replace(/\)/gi, "\)");
                        strState2Temp += strName2 + "= \"" + strVal2T + "\"|";
                    }
                }
            }
            previStrState1 = strState2Temp;
        }

        if (props.problemType === 12) {
            let strName2 = '';
            let strType2 = '';
            let strVal2 = '';
            let strState2Temp = '';
            for (let i=0; i < obj_no; i++) {
                strName2 = app.getObjectName(i);
                strType2 = app.getObjectType(strName2);
                strVal2 = app.getValueString(strName2, false);
                if (!strName2.includes("textConfirm") && strName2 !== 'trialno1' && strName2 !== 'trialno2' && strName2 !== 'trialno3' && strName2 !== 'trialno4') {
                    if (strType2 === 'numeric' || strType2 === 'point' || strType2 === 'list') {
                        strState2Temp += strVal2 + "|";
                    }
                    if (strType2 === 'text') {
                        let strVal2T1 = strVal2.replace(/\=/gi, "\=");
                        let strVal2T2 = strVal2T1.replace(/\!/gi, "\!");
                        let strVal2T3 = strVal2T2.replace(/\(/gi, "\(");
                        let strVal2T4 = strVal2T3.replace(/\//gi, "\/");
                        let strVal2T = strVal2T4.replace(/\)/gi, "\)");
                        strState2Temp += strName2 + "= \"" + strVal2T + "\"|";
                    }
                }
            }
            previStrState1 = strState2Temp;
        }

        
        function objUpdateListenerG() {
            if (props.problemType === 7) {
                let answerObj = {"problemCode":props.idIn};
                answerObj['problemTitle'] = props.title;
                // answerObj['userId'] = auth?.userId || "visit";
                answerObj['solverType'] = 3;
                let date = new Date();
                let viewDate = date.toLocaleString('en-GB');
                answerObj['solvingTime'] = viewDate;
                let visitObj = {"problemCode":props.idIn};
                visitObj['problemTitle'] = props.title;
                visitObj['solverType'] = 3;
                let answerMark = props.idIn.substring(2,10)+viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
                answerObj['answerMark'] = answerMark;
                let objNumber = app.getObjectNumber();
                let strState1 = '';
                let strState3 = '';
                let strName = '';
                let strVal = 0;
                let strType = '';
                let strComm = '';
                let strAlgorithmXML = '';
                let strVal1 = '';
                let boolVisible = false;
                let strColor = '';
                let strState7 = '';
                let strState8 = '';
                let strState11 = '';
                let intLineThickness = 0;
                let intPointSize = 0;
                let stateColor = '';
                let stateLineThickness = '';
                let statePointSize = '';
                let scoreTemp = 0;
                let scoreG = 0;
                let trialno3 = app.getValue('trialno3');

                let textConfirmV = app.getValueString('textConfirm', false);
                let textConfirmV2 = textConfirmV.replace(/\!/gi, "\!");
                let textConfirmV3 = textConfirmV2.replace(/\(/gi, "\(");
                let textConfirmV4 = textConfirmV3.replace(/\)/gi, "\)");
                let textConfirmV5 = textConfirmV4.replace(/\=/gi, "\=");
                let textConfirmVT = textConfirmV5.replace(/\//gi, "\/");
                let scoreText = app.getValueString('textScore2', false);

                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    // if (strType == "point" && !strComm.includes("Point") && !strComm.includes("Intersect")) {
                    //     strState1 += strVal + "|";
                    // }
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point' || strType === 'list') {
                            strState1 += strVal + "|";
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                        if (strName.includes("noAn") && strType === 'numeric') {
                            strState11 = strVal + "|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        // strState1 += "1" + strVal + "|";
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (strType === "point") {
                        strState8 += strVal + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (boolVisible && strType !== "point" && strType !== "boolean" && strType !== "numeric" && strType !== "button" && strType !== "text" && strType !== "textfield") {
                        strState8 += strName + "=" + strComm + "|";
                    }
                }
                scoreTemp = app.getValueString('textScore5', false);
                let scoreTemp1 = scoreTemp.replace("점", "");
                scoreG = Number(scoreTemp1);
                if (((scoreG !== previScoreG || scoreG === 100) && scoreText !== previScoreText) || strState11 !== previStrState4) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    trialno2.current = trialno2.current+1;
                    trialno4.current = 1;
                    trialno5.current = 1;
                    let isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['continuousFailureNum'] = trialno5.current;
                    answerObj['score'] = scoreG;
                    answerObj['solvingContentType'] = "typeStringValue";
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previScoreG = scoreG;
                previScoreText = scoreText;
                previStrState4 = strState11;
            }

            if (props.problemType === 701) {
                let answerObj = {"problemCode":props.idIn};
                answerObj['problemTitle'] = props.title;
                // answerObj['userId'] = auth?.userId || "visit";
                answerObj['solverType'] = 3;
                let date = new Date();
                let viewDate = date.toLocaleString('en-GB');
                answerObj['solvingTime'] = viewDate;
                let visitObj = {"problemCode":props.idIn};
                visitObj['problemTitle'] = props.title;
                visitObj['solverType'] = 3;
                let answerMark = props.idIn.substring(2,10)+viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
                answerObj['answerMark'] = answerMark;
                let objNumber = app.getObjectNumber();
                let strState1 = '';
                let strState3 = '';
                let strName = '';
                let strVal = 0;
                let strType = '';
                let strComm = '';
                let strAlgorithmXML = '';
                let strVal1 = '';
                let boolVisible = false;
                let strColor = '';
                let strState7 = '';
                let strState8 = '';
                let strState11 = '';
                let intLineThickness = 0;
                let intPointSize = 0;
                let stateColor = '';
                let stateLineThickness = '';
                let statePointSize = '';
                let scoreTemp = 0;
                let scoreG = 0;
                let trialno3 = app.getValue('trialno3');
                let textConfirm = '';

                let textConfirmV = app.getValueString('textConfirm', false);
                let textConfirmV2 = textConfirmV.replace(/\!/gi, "\!");
                let textConfirmV3 = textConfirmV2.replace(/\(/gi, "\(");
                let textConfirmV4 = textConfirmV3.replace(/\)/gi, "\)");
                let textConfirmV5 = textConfirmV4.replace(/\=/gi, "\=");
                let textConfirmVT = textConfirmV5.replace(/\//gi, "\/");
                let scoreText = app.getValueString('textScore2', false);

                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    // if (strType == "point" && !strComm.includes("Point") && !strComm.includes("Intersect")) {
                    //     strState1 += strVal + "|";
                    // }
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point' || strType === 'list') {
                            strState1 += strVal + "|";
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                        if (strName.includes("noAn") && strType === 'numeric') {
                            strState11 = strVal + "|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        // strState1 += "1" + strVal + "|";
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (strType === "point") {
                        strState8 += strVal + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (boolVisible && strType !== "point" && strType !== "boolean" && strType !== "numeric" && strType !== "button" && strType !== "text" && strType !== "textfield") {
                        strState8 += strName + "=" + strComm + "|";
                    }
                }
                scoreTemp = app.getValueString('textScore5', false);
                let scoreTemp1 = scoreTemp.replace("점", "");
                scoreG = Number(scoreTemp1);
                if (((scoreG !== previScoreG || scoreG === 100) && scoreText !== previScoreText) || strState11 !== previStrState4) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    textConfirm = app.getValueString('textConfirm', false)
                    if (textConfirm.includes("잘했어요")) {
                        trialno2.current = trialno2.current+1;
                        trialno4.current = 1;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                        trialno5.current = 0;
                    } else {
                        trialno4.current = 2;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                    }
                    let isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['score'] = scoreG;
                    answerObj['solvingContentType'] = "typeStringValue";
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previScoreG = scoreG;
                previScoreText = scoreText;
                previStrState4 = strState11;
            }

            if (props.problemType === 10) {
                let answerObj = {"problemCode":props.idIn};
                answerObj['problemTitle'] = props.title;
                // answerObj['userId'] = auth?.userId || "visit";
                answerObj['solverType'] = 3;
                let date = new Date();
                let viewDate = date.toLocaleString('en-GB');
                answerObj['solvingTime'] = viewDate;
                let visitObj = {"problemCode":props.idIn};
                visitObj['problemTitle'] = props.title;
                visitObj['solverType'] = 3;
                let answerMark = props.idIn.substring(2,10)+viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
                answerObj['answerMark'] = answerMark;
                let objNumber = app.getObjectNumber();
                let strState1 = '';
                let strState3 = '';
                let strName = '';
                let strVal = 0;
                let strType = '';
                let strComm = '';
                let strAlgorithmXML = '';
                let strVal1 = '';
                let boolVisible = false;
                let strColor = '';
                let strState7 = '';
                let strState8 = '';
                let intLineThickness = 0;
                let intPointSize = 0;
                let stateColor = '';
                let stateLineThickness = '';
                let statePointSize = '';
                let scoreTemp = 0;
                let scoreG = 0;
                let trialno3 = app.getValue('trialno3');

                let textConfirmV = app.getValueString('textConfirm', false);
                let textConfirmV2 = textConfirmV.replace(/\!/gi, "\!");
                let textConfirmV3 = textConfirmV2.replace(/\(/gi, "\(");
                let textConfirmV4 = textConfirmV3.replace(/\)/gi, "\)");
                let textConfirmV5 = textConfirmV4.replace(/\=/gi, "\=");
                let textConfirmVT = textConfirmV5.replace(/\//gi, "\/");
                let scoreText = app.getValueString('textScore2', false);

                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    // if (strType == "point" && !strComm.includes("Point") && !strComm.includes("Intersect")) {
                    //     strState1 += strVal + "|";
                    // }
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point' || strType === 'list') {
                            strState1 += strVal + "|";
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        // strState1 += "1" + strVal + "|";
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (strType === "point") {
                        strState8 += strVal + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (boolVisible && strType !== "point" && strType !== "boolean" && strType !== "numeric" && strType !== "button" && strType !== "text" && strType !== "textfield") {
                        strState8 += strName + "=" + strComm + "|";
                    }
                }
                scoreTemp = app.getValueString('textScore5', false);
                let scoreTemp1 = scoreTemp.replace("점", "");
                scoreG = Number(scoreTemp1);
                if (strState1 !== previStrState1) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    trialno2.current = trialno2.current+1;
                    trialno4.current = app.getValue('trialno4');
                    trialno5.current = 1;
                    let isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['continuousFailureNum'] = trialno5.current;
                    answerObj['score'] = scoreG;
                    answerObj['solvingContentType'] = "typeStringValue";
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previStrState1 = strState1;
            }
            if (props.problemType === 12) {
                let answerObj = {"problemCode":props.idIn};
                answerObj['problemTitle'] = props.title;
                // answerObj['userId'] = auth?.userId || "visit";
                answerObj['solverType'] = 3;
                let date = new Date();
                let viewDate = date.toLocaleString('en-GB');
                answerObj['solvingTime'] = viewDate;
                let visitObj = {"problemCode":props.idIn};
                visitObj['problemTitle'] = props.title;
                visitObj['solverType'] = 3;
                let answerMark = props.idIn.substring(2,10)+viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
                answerObj['answerMark'] = answerMark;
                let objNumber = app.getObjectNumber();
                let strState1 = '';
                let strState3 = '';
                let strName = '';
                let strVal = 0;
                let strType = '';
                let strComm = '';
                let strAlgorithmXML = '';
                let strVal1 = '';
                let boolVisible = false;
                let strColor = '';
                let strState7 = '';
                let strState8 = '';
                let intLineThickness = 0;
                let intPointSize = 0;
                let stateColor = '';
                let stateLineThickness = '';
                let statePointSize = '';
                let scoreTemp = 0;
                let scoreG = 0;
                let trialno3 = app.getValue('trialno3');

                let textConfirmV = app.getValueString('textConfirm', false);
                let textConfirmV2 = textConfirmV.replace(/\!/gi, "\!");
                let textConfirmV3 = textConfirmV2.replace(/\(/gi, "\(");
                let textConfirmV4 = textConfirmV3.replace(/\)/gi, "\)");
                let textConfirmV5 = textConfirmV4.replace(/\=/gi, "\=");
                let textConfirmVT = textConfirmV5.replace(/\//gi, "\/");
                let textConfirm1V = app.getValueString('textConfirm1', false);
                let textConfirm1V2 = textConfirm1V.replace(/\!/gi, "\!");
                let textConfirm1V3 = textConfirm1V2.replace(/\(/gi, "\(");
                let textConfirm1V4 = textConfirm1V3.replace(/\)/gi, "\)");
                let textConfirm1V5 = textConfirm1V4.replace(/\=/gi, "\=");
                let textConfirm1VT = textConfirm1V5.replace(/\//gi, "\/");
                let textConfirm2V = app.getValueString('textConfirm2', false);
                let textConfirm2V2 = textConfirm2V.replace(/\!/gi, "\!");
                let textConfirm2V3 = textConfirm2V2.replace(/\(/gi, "\(");
                let textConfirm2V4 = textConfirm2V3.replace(/\)/gi, "\)");
                let textConfirm2V5 = textConfirm2V4.replace(/\=/gi, "\=");
                let textConfirm2VT = textConfirm2V5.replace(/\//gi, "\/");
                let textConfirm3V = app.getValueString('textConfirm3', false);
                let textConfirm3V2 = textConfirm3V.replace(/\!/gi, "\!");
                let textConfirm3V3 = textConfirm3V2.replace(/\(/gi, "\(");
                let textConfirm3V4 = textConfirm3V3.replace(/\)/gi, "\)");
                let textConfirm3V5 = textConfirm3V4.replace(/\=/gi, "\=");
                let textConfirm3VT = textConfirm3V5.replace(/\//gi, "\/");
                let scoreText = app.getValueString('textScore2', false);

                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    // if (strType == "point" && !strComm.includes("Point") && !strComm.includes("Intersect")) {
                    //     strState1 += strVal + "|";
                    // }
                    if (!strName.includes("textConfirm") && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point' || strType === 'list') {
                            strState1 += strVal + "|";
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        // strState1 += "1" + strVal + "|";
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (strType === "point") {
                        strState8 += strVal + "|";
                    }
                }
                for (let i=0; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    boolVisible = app.getVisible(strName);
                    if (boolVisible && strType !== "point" && strType !== "boolean" && strType !== "numeric" && strType !== "button" && strType !== "text" && strType !== "textfield") {
                        strState8 += strName + "=" + strComm + "|";
                    }
                }
                scoreTemp = app.getValueString('textScore5', false);
                let scoreTemp1 = scoreTemp.replace("점", "");
                scoreG = Number(scoreTemp1);
                if (strState1 !== previStrState1) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    trialno2.current = trialno2.current+1;
                    trialno4.current = app.getValue('trialno4');
                    trialno5.current = 1;
                    let isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['continuousFailureNum'] = trialno5.current;
                    answerObj['score'] = scoreG;
                    answerObj['solvingContentType'] = "typeStringValue";
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previStrState1 = strState1;
            }
        };
        app.registerObjectUpdateListener('trialno1', objUpdateListenerG);

        function objUpdateListener() {
            let answerObj = {"problemCode":props.idIn};
            answerObj['problemTitle'] = props.title;
            answerObj['solverType'] = 3;
            let date = new Date();
            let viewDate = date.toLocaleString('en-GB');
            answerObj['solvingTime'] = viewDate;
            let answerMark = props.idIn.substring(2,10)+viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
            answerObj['answerMark'] = answerMark;
            let visitObj = {"problemCode":props.idIn};
            visitObj['problemTitle'] = props.title;
            visitObj['solverType'] = 3;
            let objNumber = app.getObjectNumber();
            let strState = '';
            let strState1 = '';
            let strState2 = '';
            let strState3 = '';
            let strState7 = '';
            let strState8 = '';
            let strState9 = '';
            let strName = '';
            let strVal = 0;
            let strType = '';
            let strXML = '';
            let strComm = '';
            let boolVisible = false;
            let strColor = '';
            let intLineThickness = 0;
            let intPointSize = 0;
            let strAlgorithmXML = '';
            let stateColor = '';
            let stateLineThickness = '';
            let statePointSize = '';
            let textConfirm = '';
            let score = 0;
            let trialno3 = app.getValue('trialno3');
            let isSuccess;

            let textConfirmV = app.getValueString('textConfirm', false);
            let textConfirmV2 = textConfirmV.replace(/\!/gi, "\!");
            let textConfirmV3 = textConfirmV2.replace(/\(/gi, "\(");
            let textConfirmV4 = textConfirmV3.replace(/\)/gi, "\)");
            let textConfirmV5 = textConfirmV4.replace(/\=/gi, "\=");
            let textConfirmVT = textConfirmV5.replace(/\//gi, "\/");
            let textConfirmCheckIdx = textConfirmV.indexOf("(");
            let textConfirmCheck = textConfirmV.substring(0,textConfirmCheckIdx);

            let trialno1V=app.getValue('trialno1');
            let trialno2V=app.getValue('trialno2');
            let trialno3V=app.getValue('trialno3');

            for (let i=0; i < objNumber; i++) {
                strName = app.getObjectName(i);
                strType = app.getObjectType(strName);
                strVal = app.getValueString(strName, false);
                strComm = app.getCommandString(strName, false);
                boolVisible = app.getVisible(strName);
                if (strType === "point") {
                    strState8 += strVal + "|";
                }
            }
            for (let i=0; i < objNumber; i++) {
                strName = app.getObjectName(i);
                strType = app.getObjectType(strName);
                strVal = app.getValueString(strName, false);
                strComm = app.getCommandString(strName, false);
                boolVisible = app.getVisible(strName);
                if (boolVisible && strType !== "point" && strType !== "boolean" && strType !== "numeric" && strType !== "button" && strType !== "text" && strType !== "textfield") {
                    strState8 += strName + "=" + strComm + "|";
                }
            }

            if (props.problemType === 1) {
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strXML = app.getXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && !strComm.includes("Point") && !strComm.includes("Intersect")) {
                        strState1 += strVal + "|";
                    }
                    if (strType === "point") {
                        strState9 += strVal + "|";
                    }
                    if (!strVal.includes("그림")) {
                        if (strAlgorithmXML === "") {
                            strState += "1" + strVal + "|";
                            newObjectNum.current = newObjectNum.current+1;
                        }
                        else {
                            strState += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                if(strState !== previStrState || strState9 !== previStrState3) {
                    answerObj['textConfirm'] = textConfirmCheck;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    textConfirm = app.getValueString('textConfirm', false)
                    if (textConfirm.includes("잘했어요")) {
                        trialno2.current = trialno2.current+1;
                        trialno4.current = 1;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                        trialno5.current = 0;
                    } else {
                        trialno4.current = 2;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                    }
                    isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['score'] = 100;
                    answerObj['solvingContentType'] = "typeStringCommand";
                    answerObj['solvingContent'] = strState;
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                    console.log(answerObj);
                }
                previStrState = strState;
                previStrState3 = strState9;
            }

            if (props.problemType === 2) {
                let count = 0;
                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point') {
                            strState1 += strVal + "|";
                            count = count + 1;
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strXML = app.getXML(strName);
                    strColor = app.getColor(strName);
                    intLineThickness = app.getLineThickness(strName);
                    intPointSize = app.getPointSize(strName);
                    strState7 += strType + "|";
                    if (strType === "point") {
                        strState9 += strVal + "|";
                    }
                    if (strType === "point" && strComm === "") {
                        strState2 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (strType === 'text' && strComm === "") {
                        let strValT1 = strVal.replace(/\=/gi, "\=");
                        let strValT2 = strValT1.replace(/\!/gi, "\!");
                        let strValT3 = strValT2.replace(/\(/gi, "\(");
                        let strValT4 = strValT3.replace(/\//gi, "\/");
                        let strValT = strValT4.replace(/\)/gi, "\)");
                        strState2 += "1" + strName + "= \"" + strValT + "\"|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState2 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState2 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }

                if ((strState1 !== previStrState1 || strState2 !== previStrState2 || strState9 !== previStrState3) && trialno1V !== previTrialno1) {
                    answerObj['textConfirm'] = textConfirmCheck;
                    answerObj['startAnimation'] = count;
                    trialno1.current = trialno1.current+1;
                    textConfirm = app.getValueString('textConfirm', false)
                    if (textConfirm.includes("잘했어요")) {
                        trialno2.current = trialno2.current+1;
                        trialno4.current = 1;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                        trialno5.current = 0;
                    } else {
                        trialno4.current = 2;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                    }
                    isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['score'] = 100;
                    answerObj['solvingContentType'] = "typeStringValue";
                    let strState2In = '';
                    strState2In += strState2 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState2In;
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previStrState2 = strState2;
                previStrState1 = strState1;
                previStrState3 = strState9;
            }

            if (props.problemType === 6) {
                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric' || strType === 'point') {
                            strState1 += strVal + "|";
                        }
                        if (strType === 'text') {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strXML = app.getXML(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (strType === 'text' && strComm === "") {
                        let strValT1 = strVal.replace(/\=/gi, "\=");
                        let strValT2 = strValT1.replace(/\!/gi, "\!");
                        let strValT3 = strValT2.replace(/\(/gi, "\(");
                        let strValT4 = strValT3.replace(/\//gi, "\/");
                        let strValT = strValT4.replace(/\)/gi, "\)");
                        strState3 += strName + "= \"" + strValT + "\"|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                score = app.getValue('noScore')
                if (score !== previScore) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    trialno2.current = trialno2.current+1;
                    trialno4.current = 1;
                    trialno5.current = 1;
                    isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = trialno4.current;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['continuousFailureNum'] = trialno5.current;
                    answerObj['score'] = score;
                    answerObj['solvingContentType'] = "typeStringValue"
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previScore = score;
            }

            if (props.problemType === 4) {
                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType !== 'numeric' && strType !== 'point' && strType !== 'text' && strComm !== "") {
                            strState1 += strAlgorithmXML + "|";
                        } 
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strXML = app.getXML(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (strType === 'text' && strComm === "") {
                        let strValT1 = strVal.replace(/\=/gi, "\=");
                        let strValT2 = strValT1.replace(/\!/gi, "\!");
                        let strValT3 = strValT2.replace(/\(/gi, "\(");
                        let strValT4 = strValT3.replace(/\//gi, "\/");
                        let strValT = strValT4.replace(/\)/gi, "\)");
                        strState3 += strName + "= \"" + strValT + "\"|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                if (strState1 !== previStrState1) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    textConfirm = app.getValueString('textConfirm', false)
                    if (textConfirm.includes("잘했어요")) {
                        trialno2.current = trialno2.current+1;
                        trialno4.current = 1;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                        trialno5.current = 0;
                    } else {
                        trialno4.current = 2;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                    }
                    isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['solvingContentType'] = "typeStringValue"
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previStrState1 = strState1;
            }

            if (props.problemType === 11) {
                for (let i=0; i < obj_no; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    if (strName !== 'textConfirm' && strName !== 'trialno1' && strName !== 'trialno2' && strName !== 'trialno3' && strName !== 'trialno4') {
                        if (strType === 'numeric') {
                            strState1 += strVal + "|";
                        } else if (strType === 'text' && strComm === "") {
                            let strValT1 = strVal.replace(/\=/gi, "\=");
                            let strValT2 = strValT1.replace(/\!/gi, "\!");
                            let strValT3 = strValT2.replace(/\(/gi, "\(");
                            let strValT4 = strValT3.replace(/\//gi, "\/");
                            let strValT = strValT4.replace(/\)/gi, "\)");
                            strState1 += strName + "= \"" + strValT + "\"|";
                        }
                    }
                }
                for (let i=obj_no; i < objNumber; i++) {
                    strName = app.getObjectName(i);
                    strType = app.getObjectType(strName);
                    strVal = app.getValueString(strName, false);
                    strComm = app.getCommandString(strName, false);
                    strAlgorithmXML = app.getAlgorithmXML(strName);
                    strXML = app.getXML(strName);
                    strState7 += strType + "|";
                    if (strType === "point" && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (strType === 'text' && strComm === "") {
                        let strValT1 = strVal.replace(/\=/gi, "\=");
                        let strValT2 = strValT1.replace(/\!/gi, "\!");
                        let strValT3 = strValT2.replace(/\(/gi, "\(");
                        let strValT4 = strValT3.replace(/\//gi, "\/");
                        let strValT = strValT4.replace(/\)/gi, "\)");
                        strState3 += strName + "= \"" + strValT + "\"|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else if (!strVal.includes("그림") && strComm === "") {
                        strState3 += "1" + strVal + "|";
                        newObjectNum.current = newObjectNum.current+1;
                    } else {
                        if (!strVal.includes("그림") && strComm !== "") {
                            strState3 += "2" + strAlgorithmXML + "|";
                            if (strComm.includes("Point") || strComm.includes("Intersect")) {
                                newObjectNum.current = newObjectNum.current+1;
                            } else {
                                newObjectNum.current = newObjectNum.current+1;
                                newObjectNumWithoutPoint.current = newObjectNumWithoutPoint.current+1;
                            }
                        }
                    }
                    if (strColor !== "#0000FF" && strColor !== "#000000") {
                        stateColor += strName + "," + strColor.substring(1,3) + "," + strColor.substring(3,5) + "," + strColor.substring(5) + "|";
                    }
                    if (intLineThickness >= 7) {
                        stateLineThickness += strName + "," + intLineThickness.toString() + "|";
                    }
                    if (intPointSize >=6) {
                        statePointSize += strName + "," + intPointSize.toString() + "|";
                    }
                }
                if (strState1 !== previStrState1) {
                    answerObj['textConfirm'] = textConfirmV5;
                    answerObj['startAnimation'] = obj_no;
                    trialno1.current = trialno1.current+1;
                    textConfirm = app.getValueString('textConfirm', false)
                    if (textConfirm.includes("잘했어요")) {
                        trialno2.current = trialno2.current+1;
                        trialno4.current = 1;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                        trialno5.current = 0;
                    } else {
                        trialno4.current = 2;
                        trialno5.current = trialno5.current+1;
                        answerObj['continuousFailureNum'] = trialno5.current;
                    }
                    isSuccess = trialno4.current === 1 ? true : false;
                    answerObj['tryNum'] = trialno1.current;
                    answerObj['successNum'] = trialno2.current;
                    answerObj['problemRenewalNo'] = trialno3;
                    answerObj['isSuccess'] = isSuccess;
                    visitObj['isSuccess'] = isSuccess;
                    setVisitAnswerData(visitObj);
                    answerObj['solvingContentType'] = "typeStringValue"
                    let strState3In = '';
                    strState3In += strState3 + "textConfirm=\"" + textConfirmVT + "\"";
                    answerObj['solvingContent'] = strState3In;
                    answerObj['newObjectNum'] = newObjectNum.current;
                    answerObj['newObjectNumWithoutPoint'] = newObjectNumWithoutPoint.current;
                    answerObj['solvingPointType'] = "typeStringValue";
                    answerObj['solvingPoint'] = strState1;
                    answerObj['solvingType'] = strState7;
                    answerObj['designData'] = strState8;
                    answerObj['solvingPointSize'] = statePointSize;
                    answerObj['isOpen'] = false;
                    setAnswerData(answerObj);
                }
                previStrState1 = strState1;
            }

            previTrialno3 = trialno3;
            previTrialno2 = trialno2V;
            previTrialno1 = trialno1V;
        };
        app.registerObjectUpdateListener('trialno2' || 'trialno3', objUpdateListener);
    }

    return(
        <div className="problemmain">
            <GeoGebra
                id="ggbApplet"
                width={1600}
                height={900}
                appletOnLoad={onLoad}
                showMenuBar={false}
                showAlgebraInput={false}
                showToolBar={true}
                customToolBar={props.customToolBarIn}
                showToolBarHelp={props.showToolBarHelpIn? props.showToolBarHelpIn : true}
                showResetIcon={false}
                enableLabelDrags={props.enableLabelDragIn? props.enableLabelDragIn : true}
                enableShiftDragZoom={props.enableShiftDragZoomIn? props.enableShiftDragZoomIn : false}
                enableRightClick={false}
                errorDialogsActive={false}
                useBrowserForJS={false}
                allowStyleBar={props.allowStyleBarIn}
                preventFocus={false}
                showZoomButtons={true}
                capturingThreshold={3}
                rounding="8"
                // add code here to run when the applet starts
                showFullscreenButton={true}
                scale={1}
                disableAutoScale={false}
                allowUpscale={false}
                clickToLoad={false}
                appName="classic"
                buttonRounding={0.7}
                buttonShadows={false}
                language="ko"
                ggbBase64={props.ggbBase64In}
            />
            <br/>
        </div>
    );
}

export default GeoGebraP;