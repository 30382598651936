import axios from "axios";
const BASE_URL = 'https://tiffmath.com/api';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosImagePrivate = () => axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'x-www-form-urlencoded' }
});