import { useState, useEffect } from "react";
// import axiosapi from "../api/axiosapi";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ViewMyColoring = ({canvasCode}) => {
    const {auth} = useAuth();
    const [isMyColoring, setIsMyColoring] = useState(false);
    // const [myColoring, setMyColoring] = useState([]);
    const [errMyAns, setErrMyAns] = useState();
    const [myColoringListFetched, setMyColoringListFetched] = useState([]);
    const [latestColoring, setLatestColoring] = useState('');
    const axiosPrivate = useAxiosPrivate();
    
    const viewMyColoring = async () => {
        let isMounted = true;
        const controller = new AbortController();

        setErrMyAns(false);

        try {
            setMyColoringListFetched([]);
            setIsMyColoring(false);
            
            const response = await axiosPrivate.get('/coloring/my/canvas/personal/list/', {
                params: {
                    canvasCode: canvasCode
                },
                signal: controller.signal
            });
            isMounted && response.data && response.data.length > 0 ? setIsMyColoring(true) : setIsMyColoring(false);
            
            isMounted && response.data && response.data.map((res, index) => {
                setMyColoringListFetched(prevList => [...prevList, res]);
                if(index === 0) setLatestColoring(res);
            })
                        
        } catch(err) {
            // console.log(err);
            // setMyColoring([]);
            setIsMyColoring(false);
            err?.response?.status === 500 && setErrMyAns(true);
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(()=>{
        auth?.accessToken && viewMyColoring();
    },[canvasCode]);

    return {isMyColoring, myColoringListFetched, latestColoring, errMyAns}
}

export default ViewMyColoring;