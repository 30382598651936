import React, { useRef } from 'react';
import useAuth from '../hooks/useAuth';
import Geogebra from 'react-geogebra';

function GeoGebraMySol(props) {
    const { auth } = useAuth();

    const onLoad = () => {
        const app = window.ggbApplet;
        let obj_no = app.getObjectNumber();

        if (props.problemType === 1) {
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 2) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 4) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalXML(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 6) {
            app.setVisible("textNo", true);
            app.setVisible("textNo1", true);
            app.setVisible("textNo2", true);
            app.setVisible("textNo3", true);
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 7) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 701) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 10) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 11) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        } else if (props.problemType === 12) {
            for (let i=0; i < props.myPointAnswer.length; i++) {
                app.evalCommand(props.myPointAnswer[i]);
            }
            for (let j=0; j < props.myAnswerStep.length; j++) {
                let flagSolvingContent = props.myAnswerStep[j].slice(0,1);
                let dataSolvingContent = props.myAnswerStep[j].slice(1);
                if (flagSolvingContent === '1') {
                    app.evalCommand(dataSolvingContent);
                } else if (flagSolvingContent === '2') {
                    app.evalXML(dataSolvingContent);
                }
            }
        }
    }

    return(
        <div className="problemmain">
            <Geogebra
                id="ggbApplet"
                width={1600}
                height={900}
                appletOnLoad={onLoad}
                showMenuBar={false}
                showAlgebraInput={false}
                showToolBar={true}
                customToolBar={props.customToolBarIn}
                showToolBarHelp={true}
                showResetIcon={false}
                enableLabelDrags={false}
                enableShiftDragZoom={false}
                enableRightClick={false}
                errorDialogsActive={false}
                useBrowserForJS={false}
                allowStyleBar={props.allowStyleBarIn}
                preventFocus={false}
                showZoomButtons={false}
                capturingThreshold={3}
                rounding="8"
                // add code here to run when the applet starts
                showFullscreenButton={true}
                scale={1}
                disableAutoScale={false}
                allowUpscale={false}
                clickToLoad={false}
                appName="classic"
                buttonRounding={0.7}
                buttonShadows={false}
                language="ko"
                showAnimationButton={true}
                ggbBase64={props.ggbBase64In}
            />
            <br/>
        </div>
    );
}

export default GeoGebraMySol;