import axiosapi from "../api/axiosapi";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import "./home.css";
import Footer from "../components/Footer";

const GradeTotalListBrainUp = () => {
    const [brainUpList, setBrainUpList] = useState([]);
    const [sampleBrainUpList, setSampleBrainUpList] = useState([]);
    const [randomString, setRandomString] = useState();

    useEffect(() => {
        const fetchBrainUpList = async () => {
            try {
                const response = await axiosapi.get('/phase/brainup/list')
                response.data && setBrainUpList(response.data);
            } catch(err) {
                console.log(err)
            }
        }

        const fetchSampleBrainUpList = async () => {
            try {
                const response2 = await axiosapi.get('/sample/subject/brainup/')
                response2.data && setSampleBrainUpList(response2.data);
            } catch(err) {
                console.log(err)
            }
        }

        fetchBrainUpList();
        fetchSampleBrainUpList();
    },[])

    return(
        <div className="vertical-flex-container-1">
            <div className="content-box-1">
                <div className="card-list-title">
                    <h4 style={{color:"sienna", fontWeight:"bold"}}>그리며 생각 키우기 샘플</h4>
                </div>
                <div className="card-container">
                    {/* <div className="containerListItem">
                        <div className="improveAbilityCards"> */}
                            {sampleBrainUpList && sampleBrainUpList.map( subject => {
                                return(
                                    <div key={subject.id} className="card">
                                        <h5>{subject.sampleSubjectTitle}</h5>
                                        <p>{subject.sampleSubjectDescription}</p>
                                        <Link 
                                            to={`/mathsubject/sample/${subject.urlCode}` }
                                            state={{
                                                "subjectCode": subject.subjectCode,
                                                "problemCode": subject.firstProblemCode,
                                                "subjectTitle": subject.sampleSubjectTitle
                                            }}
                                        >
                                            바로가기
                                        </Link>
                                    </div>
                                )
                            })}
                        {/* </div>
                    </div> */}
                </div>
            </div>
            <div className="content-box-1">
                {brainUpList && brainUpList.map(grade => {
                    return(
                        <div key={grade.phaseCode}>
                            <div className="card-list-title">
                                <h4 style={{color:"sienna", fontWeight:"bold"}}>{grade.phaseTitle}</h4>
                            </div>
                            <div className="card-container">
                                {/* <div className="containerListItem">
                                    <div className="improveAbilityCards"> */}
                                        {grade.subjects && grade.subjects.map( subject => {
                                            return(
                                                <div key={subject.subjectCode} className="card">
                                                    <h5>{subject.subjectTitle}</h5>
                                                    <p>{subject.subjectDescription}</p>
                                                    <Link 
                                                        to={`/mathsubject/${randomString}` }
                                                        state={{
                                                            "subjectCode": subject.subjectCode,
                                                            "problemCode": subject.firstProblemCode,
                                                            "subjectTitle": subject.subjectTitle
                                                        }}
                                                    >
                                                        바로가기
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    {/* </div>
                                </div> */}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{width: "100%"}}>
                <Footer/>
            </div>
        </div>
    )
}

export default GradeTotalListBrainUp;