import React, { useEffect, useState } from "react";
// import './signup.css';
// import '../App.css';
// import { useNavigate, useLocation } from "react-router-dom";
import axiosapi from "../api/axiosapi";

const ViewFreemembershipAgreement = () => {
    const [freeMembershipAgreementO, setFreeMembershipAgreementO] = useState([]);
    const [err, setErr] = useState('');
    // const navigateto = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        const fetchFreeMembership = async() => {
            try {
                const response = await axiosapi.get('/policy/list/policy002')
                const freeMembershipAgreementO = response.data && response.data.content.split("|");
                setFreeMembershipAgreementO(freeMembershipAgreementO)
            } catch (err) {
                setErr(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchFreeMembership();

        return () => controller.abort();
    },[])

   return(
       <div className="App-full-container">
           <div className="position-center-box-2-1">
               <div>
                   <h2>이용약관</h2>
                   <br></br>
                   <div>{freeMembershipAgreementO && freeMembershipAgreementO.map(item => (<div><p>{item}</p></div>))}</div>
               </div>
           </div>
       </div>
   )
}

export default ViewFreemembershipAgreement;