import React from 'react';
import { Link } from 'react-router-dom';

const ForTeacherMenu = () => {
       return(
              <div>
                     <Link to={`/teacher/student/searchstudent`}>
                            <h5>학생 찾기</h5>
                     </Link>
                     <Link to={`/teacher/student/viewregisteredstudentlist`}>
                            <h5>학생 관리</h5>
                     </Link>
                     <Link to={`/teacher/myinfo/answer/list/`}>
                            <h5>내 풀이 보기</h5>
                     </Link>
                     <Link to={`/teacher/myinfo/`}>
                            <h5>내 정보</h5>
                     </Link>
                     <Link to={`/teacher/myinfo/update/`}>
                            <h5>회원정보 수정</h5>
                     </Link>
                     <Link to={`/teacher/myinfo/changepassword/`}>
                            <h5>비밀번호 변경</h5>
                     </Link>
                     <Link to={`/teacher/myinfo/payhistory/`}>
                            <h5>결제이력</h5>
                     </Link>
              </div>
       )
}

export default ForTeacherMenu;