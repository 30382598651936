import React from "react";
// import './mathsubject.css';
import MyInfo from "../components/MyInfo";
import ForStudentMenu from "../components/ForStudentMenu";

const StudentMyInfo = () => {
       return (
              <div className="App-full-row-container">
                     <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                            <ForStudentMenu />
                     </div>
                     <div className="content-box-1">
                            <MyInfo />
                     </div>
              </div>
       )
}

export default StudentMyInfo;