import React, { useEffect, useState } from "react";
// import './signup.css';
// import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import axiosapi from "../api/axiosapi";

const ViewPremiumServiceAgreement = () => {
    const [premiumServiceAgreementO, setPremiumServiceAgreementO] = useState([]);
    const [err, setErr] = useState('');
    const navigateto = useNavigate();

    useEffect(() => {
       const controller = new AbortController();

       const fetchPremiumServiceAgreement = async() => {
           try {
               const response = await axiosapi.get('/policy/list/policy003');
               const premiumServiceAgreementO = response.data && response.data.content.split("|");
               setPremiumServiceAgreementO(premiumServiceAgreementO);
           } catch (err) {
               setErr(err.message);
               if (err.response) {
                   console.log(err.response.data);
                   console.log(err.response.status);
                   console.log(err.response.headers);
               } else {
                   console.log(`Error: ${err.message}`);
               }
           }
       }

       fetchPremiumServiceAgreement();

       return () => controller.abort();
   },[])

   return(
       <div className="App-full-container">
           <div className="position-center-box-2-1">
               <div>
                   <h2>유료서비스 이용약관</h2>
                   <br></br>
                   <div>{premiumServiceAgreementO && premiumServiceAgreementO.map(item => (<div><p>{item}</p></div>))}</div>
               </div>
           </div>
       </div>
   )
}

export default ViewPremiumServiceAgreement;