import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const ViewMyAnswerList = () => {
       const axiosPrivate = useAxiosPrivate();
       const [selectedPeriod, setSelectedPeriod] = useState(0);
       const [myAnswerList, setMyAnswerList] = useState([]);
       const [loading, setLoading] = useState(true);
       const [error, setError] = useState(null);

       useEffect(() => {
              const fetchMyAnswerList = async () => {
                     try {
                            const response = await axiosPrivate.get('/solve/myanswer/totallist/byperiod/',  {
                                   params: {
                                          period: selectedPeriod
                                      },
                                   withCredentials: true
                            })
                            response.data && setMyAnswerList(response.data);
                     } catch (err) {
                            setError(err.message);
                            console.log(err);
                     } finally {
                            setLoading(false);
                     }
              }

              fetchMyAnswerList();
       },[selectedPeriod]);

       if (loading) return <p>Loading...</p>;
       // if (error) return <p>Error: {error}</p>;
       return (
              <div>
                     <h2>풀이 내역 조회</h2>
                     <br></br>
                     {/* <label>조회할 기간 선택:</label> */}
                     <select value={selectedPeriod} style={{height:"36px", width:"40%"}} onChange={(e) => setSelectedPeriod(e.target.value)}>
                            <option value>조회할 기간을 선택하세요.</option>
                            <option value={1}>최근 1개월</option>
                            <option value={2}>최근 2개월</option>
                            <option value={3}>최근 3개월</option>
                     </select>

                     <h4 style={{marginTop:"36px"}}>회원님의 문제 풀이 리스트</h4>
                     <table style={{width:"100%", maxWidth:"900px"}}>
                            <thead style={{ backgroundColor: 'yellow', height:"37px" }}>
                                   <tr>
                                          <th style={{minWidth:"240px", width:"60%"}}>문제</th>
                                          <th style={{minWidth:"100px", width:"25%"}}>풀이 시각</th>
                                          <th style={{minWidth:"60px", width:"15%"}}>풀이 여부</th>
                                   </tr>
                            </thead>
                            <tbody>
                                   {myAnswerList.map((answer, index) => {
                                          return (
                                                 <tr key={answer.id} style={{ height:"37px", backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey' }}>
                                                        <td>
                                                               <Link 
                                                                      to={answer.isTeacher ? `/teacher/myinfo/answer/item/${answer.answerMark}` 
                                                                             : `/student/myinfo/answer/item/${answer.answerMark}`
                                                                      }
                                                                      state={{
                                                                             "myAnswer": answer,
                                                                             "myAnswerList": myAnswerList
                                                                      }}
                                                               >
                                                                      {answer.problemTitle && splitContent(answer.problemTitle)}
                                                               </Link>
                                                        </td>
                                                        <td>{answer.solvingTime}</td>
                                                        <td>{answer.isSuccess ? "풀이 성공" : "풀이 실패"}</td>
                                                 </tr>
                                          )
                                   })}
                            </tbody>
                     </table>
              </div>
       )
}

export default ViewMyAnswerList;