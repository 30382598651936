import React, { useState } from "react";
// import axiosapi from "../api/axiosapi";
// import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ForTeacherMenu from "../components/ForTeacherMenu";

const SearchStudent = () => {
	// const {auth} = useAuth();
	const [searchTerm, setSearchTerm] = useState("");
	// const [studentID, setStudentID] = useState("");
	// const [studentName, setStudentName] = useState("");
	const [searchBy, setSearchBy] = useState("username");
	const [students, setStudents] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const [count, setCount] = useState(0);
	const axiosPrivate = useAxiosPrivate();
	const navigateto=useNavigate();
	const location = useLocation();

	const handleSearch = async (e) => {
		let isMounted = true;
        const controller = new AbortController();

		e.preventDefault();
		setStudents([]);
		try {
			const response = await axiosPrivate.get(`/teacher/student/findstudent?searchTerm=${searchTerm}&searchBy=${searchBy}`, {
				signal: controller.signal
			});
			console.log(response.data);
			isMounted && setStudents(response.data);
		} catch (err) {
			err?.response?.status === 500 && alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
		}
		setCount(count + 1);

		return () => {
            isMounted = false;
            controller.abort();
        }
	};

	const handleSelectStudent = async (student) => {
		let isMounted = true;
        const controller = new AbortController();

		console.log(student);
		// 선택한 학생을 서버에 등록하는 요청
		try {
			const response = await axiosPrivate.post('/teacher/student/registerstudent', {
				studentID: student.username,
				studentName: student.name},
				{
					signal: controller.signal
				}
			);
			isMounted && response.data && alert(response.data.message);
			// console.log("data: ", response.data);
			// console.log("message: ", response.message);
		} catch (err) {
			// console.log(err);
			err?.response?.status === 500 && alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
		}

		return () => {
            isMounted = false;
            controller.abort();
        }
	};

	return (
		<div className="App-full-row-container">
			<div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
				<ForTeacherMenu />
			</div>
			<div className="content-box-1">
				<div>
					<select value={searchBy} onChange={(event) => setSearchBy(event.target.value)} style={{marginRight:"7px", height:"37px"}}>
						<option value="username">아이디</option>
						<option value="name">이름</option>
					</select>
					<input
						type="text"
						placeholder="검색어를 입력하세요"
						onChange={(event) => setSearchTerm(event.target.value)}
						style={{marginRight:"10px", height:"37px", width:"250px"}}
					/>
					<button className="button" onClick={handleSearch} >검색</button>
				</div>
				<h4 style={{ marginTop:"20px", marginBottom:"10px" }}>학생 목록</h4>
				<div>
					{students.length ? (
						<table style={{width:"100%", maxWidth:"700px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"37px" }}>
								<tr>
									<th style={{minWidth:"150px", width:"37%"}}>이름</th>
									<th style={{minWidth:"150px", width:"37%"}}>아이디</th>
									<th style={{ width: '100px', textAlign: 'right' }}></th>
								</tr>
							</thead>
							<tbody>
								{students.map((student, index) => (
									<tr key={student.id} style={{ height:"37px", backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey' }}>
										<td>{student.name}</td>
										<td>{student.username}</td>
										<td style={{ width: '100px', textAlign: 'right' }}><button className="button" style={{margin:"3px 3px"}} onClick={() => setSelectedStudent(student)}>선택</button></td>
									</tr>
								))}
							</tbody>
						</table>
					) : (count > 0 && `검색한 학생이 없습니다. 학생의 ${searchBy === "username" ? "아이디를" : "이름을"} 확인한 후 다시 검색하세요.`)
					}
				</div>
				{selectedStudent && (
					<div style={{ marginTop:"30px"}}>
						<h5 style={{ marginBottom:"10px"}}>다음 학생을 등록하시겠습니까? 선생님의 등록 후 학생이 등록 확인을 해야 등록이 완료됩니다.</h5>
						<table style={{width:"100%", maxWidth:"700px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"37px" }}>
								<tr>
									<th style={{minWidth:"150px", width:"50%"}}>이름</th>
									<th style={{minWidth:"150px", width:"50%"}}>아이디</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height:"37px" }}>
									<td>{selectedStudent.name}</td>
									<td>{selectedStudent.username}</td>
								</tr>
							</tbody>
						</table>
						<button className="button" onClick={() => handleSelectStudent(selectedStudent)}>등록</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchStudent;
