import React from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import './mathsubject.css';
import ForTeacherMenu from "../components/ForTeacherMenu";
import ViewMyAnswerList from "../components/ViewMyAnswerList";

const TeacherMyAnswerList = () => {
       return (
              <div className="App-full-row-container">
                     <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                            <ForTeacherMenu />
                     </div>
                     <div className="content-box-1">
                            <ViewMyAnswerList />
                     </div>
              </div>
       )
}

export default TeacherMyAnswerList;