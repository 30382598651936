import React, { useEffect, useState } from "react";
// import './signup.css';
// import '../App.css';
// import { useNavigate, useLocation } from "react-router-dom";
import axiosapi from "../api/axiosapi";

const ViewCopyright = () => {
    const [copyrightO, setCopyrightO] = useState([]);
	const [err, setErr] = useState('');
    // const navigateto = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        const fetchCopyright = async() => {
            try {
                const response = await axiosapi.get('/policy/list/policy004');
                const copyrightO = response.data && response.data.content.split("|");
                setCopyrightO(copyrightO);
            } catch (err) {
                setErr(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchCopyright();

        return () => controller.abort();
    },[])

    return(
        <div className="App-full-container">
            <div className="position-center-box-2-1">
                <div style={{marginTop:"25px"}}>
                    <h2>저작권 보호규정</h2>
                    <br></br>
                    <div>{copyrightO && copyrightO.map(item => (<div><p>{item}</p></div>))}</div>
                </div>
            </div>
        </div>
    )
}

export default ViewCopyright;