import React from 'react';
import GeoGebraMySolAnimation from '../components/GeoGebraMySolAnimation';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
// import './mathsubject.css';
import ForTeacherMenu from '../components/ForTeacherMenu';

const TeacherMyAnswerAnimation = ()=> {
    const {teacherMyAnswerMarkAni}=useParams();
    const navigateto = useNavigate();
    const {myAnswer, myAnswerList, myConstructionStep, myAnswerRaw, myPointConstruction} = useLocation().state;
    
    return(
        <div className="App-full-row-container">
            <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                <ForTeacherMenu />
            </div>
            <div className="content-box-1">
                <div className="vertical-flex-container" key={myAnswerRaw.answerMark}>
                    <div className="content-fit">
                        <h4>{myAnswerRaw.problemTitle} | {myAnswerRaw.solvingTime.substring(6,10)}년 {myAnswerRaw.solvingTime.substring(3,5)}월 {myAnswerRaw.solvingTime.substring(0,2)}일 {myAnswerRaw.solvingTime.substring(12,17)}의 풀이</h4>
                        <GeoGebraMySolAnimation
                            idIn={myAnswerRaw.problemCode}
                            customToolBarIn={myAnswerRaw.customToolBar}
                            allowStyleBarIn={myAnswerRaw.allowStyleBar}
                            ggbBase64In={myAnswerRaw.problemContent}
                            myPointAnswer={myPointConstruction}
                            myAnswerStep={myConstructionStep}
                            problemType={myAnswerRaw.problemType}
                            startAnimation={myAnswerRaw.startAnimation}
                        />
                        <div className='horizontal-flex-container'>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/myinfo/answer/list/`)}
                            >
                                문제 풀이 목록
                            </button>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/myinfo/answer/item/${teacherMyAnswerMarkAni}`, {state:{
                                    "myAnswer": myAnswer,
                                    "myAnswerList": myAnswerList
                                }})}
                            >
                                풀이 최종 화면
                            </button>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default TeacherMyAnswerAnimation;