import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DaumPostCode from "react-daum-postcode";
// import '../pages/signup.css';

function UpdateMyInfo() {
       const axiosPrivate = useAxiosPrivate();
       const navigateto = useNavigate();
       const [password, setPassword] = useState('');
       const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
       const [userData, setUserData] = useState({
              name: '',
              lastname: '',
              nation: '',
              email: '',
              mobile: '',
              phoneNumber: '',
              zipcode: '',
              address: '',
              detailAddress: '',
              birthYear: ''
       });
       const [count, setCount] = useState(1);
       const [isPopupOpen, setIsPopupOpen] = useState(false);

       // 비밀번호 확인 요청
       const handlePasswordCheck = async () => {
              try {
                     const response = await axiosPrivate.post('/user/my/password', { password },
                            {
                                withCredentials: true
                            }
                     );
              
                     if (response.data.isSuccess) {
                            setIsPasswordConfirmed(true);
                            setCount(count+1);
                     } else {
                            alert('비밀번호가 틀렸습니다.');
                     }
              } catch (error) {
                     console.error('Error checking password:', error);
              }
       };

       // 컴포넌트가 마운트될 때 고객 정보를 불러옴
       useEffect(() => {
              const fetchUserData = async () => {
                     try {
                            const response2 = await axiosPrivate.get('/user/my/info', {withCredentials: true});
                            response2.data && setUserData(prevState => ({
                                   ...prevState,
                                   name: response2.data.name,
                                   email: response2.data.email,
                                   mobile: response2.data.mobile,
                                   zipcode: response2.data.zipcode,
                                   address: response2.data.address,
                                   detailAddress: response2.data.detailAddress,
                                   birthYear: response2.data.birthYear
                            }));
                     } catch (error) {
                            console.error('Error fetching user data:', error);
                     }
              };
       
              if (isPasswordConfirmed) {
                     fetchUserData(); // 비밀번호 확인 후 데이터 가져오기
              }
       }, [count]);

       const openZipcode = () => {
              setIsPopupOpen(true);
       }
       
       // zipcode
       const handleAddress = (data) => {
              let fullAddr = data.address;
              let extraAddr = '';

              if (data.addressType === 'R') {
                     if (data.bname !== '') {
                            extraAddr += data.bname;
                     }
                     if (data.buildingName !== '') {
                            extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
                     }
                     fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
              }
              setUserData(prevState => ({
                     ...prevState,
                     zipcode: data.zonecode,
                     address: fullAddr
              }))
              setIsPopupOpen(false)
       };
              
       // DaumPostCode style
       const width = 400;
       const height = 500;
       const modalStyle = {
              position: "relative",
              top: 10,
              border: "1px solid #000000",
       };

       // 회원정보 수정 요청
       const handleUserUpdate = async () => {
              try {
                     const response = await axiosPrivate.post('/user/update/userinfo', userData,
                            {
                                withCredentials: true
                            });
                     
                     if (response.data.isSuccess) {
                            alert('회원정보를 성공적으로 수정했습니다.');
                     } else {
                            alert('회원정보 수정에 실패했습니다.');
                     }
              } catch (error) {
                     console.error('Error updating user info:', error);
              }
       };

       return (
              <div className="vertical-flex-container-1">
                     <div className="position-center-box-2">
                            {!isPasswordConfirmed ? (
                                   <div>
                                          <h2 style={{marginBottom:"25px"}}>비밀번호 확인</h2>
                                          {/* <label htmlFor="password" style={{marginRight:"7px"}}>비밀번호</label>  */}
                                          <input
                                                 id="password"
                                                 style={{width:"75%", height:"36px", marginRight:"7px", border: "1px solid lightgray"}}
                                                 type="password"
                                                 // placeholder="비밀번호를 입력하세요"
                                                 value={password}
                                                 onChange={(e) => setPassword(e.target.value)}
                                          />
                                          <button className='button' onClick={handlePasswordCheck}>확인</button>
                                   </div>
                            ) : (
                                   <div>
                                          <h2 style={{marginBottom:"25px"}}>회원정보 수정</h2>
                                          <div style={{marginBottom:"20px"}}>
                                                 <div style={{marginBottom:"7px"}}>
                                                        <label htmlFor="name">이름</label>
                                                 </div>
                                                 <div>
                                                        <input
                                                               id="name"
                                                               style={{width:"60%", height:"36px", border: "1px solid lightgray"}}
                                                               type="text"
                                                               placeholder="이름"
                                                               value={userData.name}
                                                               onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                                        />
                                                 </div>
                                          </div>
                                          <div style={{marginBottom:"20px"}}>
                                                 <div style={{marginBottom:"7px"}}>
                                                        <label htmlFor="email">이메일</label>
                                                 </div>
                                                 <div>
                                                        <input
                                                               id="email"
                                                               style={{width:"60%", height:"36px", border: "1px solid lightgray"}}
                                                               type="email"
                                                               placeholder="이메일"
                                                               value={userData.email}
                                                               onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                        />
                                                 </div>
                                          </div>
                                          <div style={{marginBottom:"20px"}}>
                                                 <div style={{marginBottom:"7px"}}>
                                                        <label htmlFor="mobile">휴대폰</label>
                                                 </div>
                                                 <div>
                                                        <input
                                                               id="mobile"
                                                               style={{width:"60%", height:"36px", border: "1px solid lightgray"}}
                                                               type="mobile"
                                                               placeholder="휴대폰"
                                                               value={userData.mobile}
                                                               onChange={(e) => setUserData({ ...userData, mobile: e.target.value })}
                                                        />
                                                 </div>
                                          </div>
                                          <div style={{marginBottom:"20px"}}>
                                                 <div style={{marginBottom:"7px"}}>
                                                        <label htmlFor="zipcode">주소</label>
                                                 </div>
                                                 <div style={{marginBottom:"3px"}}>
                                                        <input 
                                                               id="zipcode" 
                                                               style={{width:"30%", height:"36px", border: "1px solid lightgray"}}
                                                               type="text" 
                                                               // className="form-control" 
                                                               value={userData.zipcode} 
                                                               onClick={openZipcode} 
                                                               placeholder="우편번호" 
                                                               readOnly 
                                                        />
                                                        <button style={{borderRadius: '0', height:"36px"}} type="button" onClick={openZipcode}>
                                                               우편번호 검색
                                                        </button>
                                                        {isPopupOpen ? (
                                                               <DaumPostCode
                                                                      onComplete={handleAddress}
                                                                      autoClose
                                                                      width={width}
                                                                      height={height}
                                                                      style={modalStyle}
                                                                      isDaumPost={isPopupOpen}
                                                               />
                                                        ) : null}
                                                 </div>
                                                 <div style={{marginBottom:"3px"}}>
                                                        {/* <label htmlFor="address" style={{width:"90px", marginRight:"7px"}}>주소</label> */}
                                                        <input 
                                                               id="address" 
                                                               style={{width:"100%", height:"36px", border: "1px solid lightgray"}}
                                                               type="text" 
                                                               // className="form-control" 
                                                               value={userData.address} 
                                                               placeholder="주소" 
                                                               readOnly 
                                                        />
                                                 </div>
                                                 <div>
                                                        {/* <label htmlFor="detailAddress" style={{width:"90px", marginRight:"7px"}}>상세주소</label> */}
                                                        <input
                                                               id="detailAddress"
                                                               style={{width:"100%", height:"36px", border: "1px solid lightgray"}}
                                                               type="text"
                                                               placeholder="상세주소"
                                                               value={userData.detailAddress}
                                                               onChange={(e) => setUserData({ ...userData, detailAddress: e.target.value })}
                                                        />
                                                 </div>
                                          </div>
                                          <div style={{marginBottom:"20px"}}>
                                                 <div style={{marginBottom:"7px"}}>
                                                        <label htmlFor="birthYear">출생연도</label>
                                                 </div>
                                                 <div>
                                                 <input
                                                        id="birthYear"
                                                        style={{width:"60%", height:"36px", border: "1px solid lightgray"}}
                                                        type="birthYear"
                                                        placeholder="출생연도"
                                                        value={userData.birthYear}
                                                        onChange={(e) => setUserData({ ...userData, birthYear: e.target.value })}
                                                 />
                                                 </div>
                                          </div>
                                          <div className="horizontal-flex-container">
                                                 <button className='button' onClick={handleUserUpdate}>
                                                        정보 수정
                                                 </button>
                                          </div>
                                   </div>
                            )}
                     </div>
              </div>
       );
}

export default UpdateMyInfo;
