import { useState, useEffect } from "react";
// import axiosapi from "../api/axiosapi";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ViewMyAnswer = ({problemCode}) => {
    const {auth} = useAuth();
    const [isMyAnswer, setIsMyAnswer] = useState(false);
    // const [myAnswer, setMyAnswer] = useState([]);
    const [errMyAns, setErrMyAns] = useState(false);
    const [myAnswerList, setMyAnswerList] = useState([]);
    const [latestAnswer, setLatestAnswer] = useState('');
    const axiosPrivate = useAxiosPrivate();
    
    const viewMyAnswer = async () => {
        let isMounted = true;
        const controller = new AbortController();

        setErrMyAns(false);

        try {
            setMyAnswerList([]);
            setIsMyAnswer(false);
            
            const response = await axiosPrivate.get('/solve/myanswer/myanswerlist', {
                params: {
                    problemCode: problemCode
                },
                signal: controller.signal
            });

            isMounted && response.data && response.data.length > 0 && response.data.map((res, index) => {
                setMyAnswerList(prevList => [...prevList, res]);
                if(index === 0) setLatestAnswer(res);
            })
            isMounted && response.data && response.data.length > 0 ? setIsMyAnswer(true) : setIsMyAnswer(false);
            isMounted && response.data && setErrMyAns(false);
                        
        } catch(err) {
            // console.log(err);
            // setMyAnswer([]);
            setIsMyAnswer(false);
            err?.response?.status === 500 && setErrMyAns(true);
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(()=>{
        auth?.accessToken && viewMyAnswer();
    },[problemCode]);

    return {isMyAnswer, myAnswerList, latestAnswer, errMyAns}
}

export default ViewMyAnswer;