import './App.css';
import NavBar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import ProtectedRoutes from './ProtectedRoutes';
import { useState, useEffect } from 'react';

function App() {
    const [currentPath, setCurrentPath] = useState("/");

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);
    
    return (
        // <div className="App-container">
        <div>
            {/* <div className="boxApp-1"> */}
            <div>
                <NavBar currentPath={currentPath} />
            </div>
            <div>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                    {PublicRoutes.map(m => {
                        return(
                            <Route
                                key={m.path}
                                path={m.path}
                                element={<m.element />}
                                required={m.required}
                            />
                        )
                    })}
                    </Route>
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth />}>
                            {ProtectedRoutes.map(pm => {
                            return(
                                <Route
                                    key={pm.path}
                                    path={pm.path}
                                    element={<pm.element />}
                                    required={pm.required}
                                />
                            )
                            })}
                        </Route>
                    </Route>
                </Routes>
            </div>
        </div>
    );
}

export default App;
