import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import './login.css'
// import '../App.css'

const ForgotIdSecondEmail = () => {
    const location = useLocation();
    const navigateto = useNavigate();
    const [hiddenUsername, setHiddenUsername] = useState('');
    
    const HideCharater = () => {
        let hiddenCharacter="";
        for (let i = 1; i < location.state.usernameLength+1; i++) {
            if(i <= location.state.usernameLength/2) {
                hiddenCharacter=hiddenCharacter+location.state.username.substring(i-1,i)
            } else {
                hiddenCharacter=hiddenCharacter+"*"
            }
        }
        setHiddenUsername(hiddenCharacter);
        return(
            <div style={{border: "1px solid black", borderRadius:"10px", padding:"10px"}}>
                {location.state.name}님의 아이디: {hiddenUsername}
            </div>
        )
    }

    return(
        <div className="App-full-screen-container">
            <div className="position-center-box-1-1">
                <div className="vertical-flex-container">
                    <div className="width-100-percent-box">
                        {location.state.name}/{location.state.email} 님으로 찾은 아이디입니다.<br/>
                        개인정보보호를 위해 {location.state.usernameLength}자리의 아이디 중 일부는 *로 표기됩니다.
                    </div>
                    <div className="width-100-percent-box">
                        <HideCharater />
                    </div>
                    <div className="width-100-percent-box">
                        <div className="horizontal-flex-container" style={{ justifyContent: "center" }}>
                            <button 
                                className="button" 
                                style={{ width:"100px" }} 
                                onClick={() => navigateto('/login')}
                            >
                                로그인
                            </button>
                            <button 
                                className="button"
                                style={{ width:"100px" }} 
                                onClick={() => navigateto(`/forgotid/third`, {state:{
                                    bridgeway: 1
                                }})}
                            >
                                다음 단계
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotIdSecondEmail;