import React, { useState } from 'react';
import {Link, NavLink} from 'react-router-dom'
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
// import axiosapi from '../api/axiosapi';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const NavBar=({ currentPath }) => {
    const logout = useLogout();
    const {auth} = useAuth();
    const [areas, setAreas] =useState(null);
    // const [err, setErr] = useState();
    const isViewImage = currentPath.startsWith("/viewimage");

    if (isViewImage) {
        return null;
    }

    return(
        <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <img src='https://tiffmath.com/image/tiffmath-logo.jpg' height="40px" />
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                        <ul className="navbar-nav gap-1">
                            <li className="nav-item">
                                <NavLink 
                                    className="nav-link {(navData) => (navData.isActive ? 'active-style' : 'none')}" 
                                    to="/"
                                    // activeClassName="active"
                                >
                                    홈
                                </NavLink>
                            </li>
                            {areas && areas.map(area => {
                                return(
                                    <li className="nav-item dropdown" key={area.id}>
                                        <Link 
                                            className="nav-link dropdown-toggle" 
                                            to="#" 
                                            id="navbarDropdownMenuLink" 
                                            data-bs-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"
                                        >
                                            {area.areaTypeTitle}
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            {area.grade && area.grade.map(grade => {
                                                return(
                                                    <Link 
                                                        className="dropdown-item" 
                                                        to={`/grade/${grade.gradeID}`} 
                                                        key={grade.gradeID}
                                                    >
                                                        {grade.gradeTitle}
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        <ul className="navbar-nav gap-2">
                            {auth?.accessToken
                                ? <div className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link {(navData) => (navData.isActive ? 'active-style' : 'none')}" 
                                                onClick={() => logout()}
                                                to="/"
                                                // activeClassName="active"
                                            >
                                                로그아웃
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link {(navData) => (navData.isActive ? 'active-style' : 'none')}" 
                                                to="/payment/1"
                                                replace
                                                // activeClassName="active"
                                            >
                                                결제하기
                                            </NavLink>
                                        </li>
                                    </div>
                                : <div className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link {(navData) => (navData.isActive ? 'active-style' : 'none')}" 
                                                to="/login"
                                                replace
                                                // activeClassName="active"
                                            >
                                                로그인
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link {(navData) => (navData.isActive ? 'active-style' : 'none')}" 
                                                to="/freeagreement"
                                                replace
                                                // activeClassName="active"
                                            >
                                                회원가입
                                            </NavLink>
                                        </li>
                                    </div>
                            }
                            {/* <li className="nav-item">
                                <form className="d-flex form-inline">
                                    <input className="form-control mx-2 mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                                    <button className="btn btn-success my-2 my-sm-0" type="submit">Search</button>
                                </form>
                            </li> */}
                        </ul>
                    </div>
                </div>
            
        </nav>
    );
}

export default NavBar;