import { React, useEffect, useState } from 'react';
import GeoGebraMySolAnimation from '../components/GeoGebraMySolAnimation';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';

const ImproveMathAbilityMySolAnimation = ()=> {
    const navigateto=useNavigate();
    const {cateProbIDMy}=useParams();
    const {categoryCode, problemCode, improveAbilityTitle, problem, useStat, myAnswerList, myPointConstruction, isFirst, isLast, prevProblem, prevUrlAdd, nextProblem, nextUrlAdd, cateProbID, myConstructionStep, myAnswerRaw, improveAbilityList, improveAbilityType} = useLocation().state;
    const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();

    useEffect(() => {
        const revivalData = () => {
            const letters = '1234567890';
            let randomString1l = '';
            // let randomString2l = '';
            // let randomString3l = '';
            // let randomString4l = '';
            // let randomString5l = '';
            for (let i = 0; i < 3; i++) {
                randomString1l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString1(randomString1l); 
            // for (let i = 0; i < 8; i++) {
            //     randomString2l += letters[Math.floor(Math.random() * letters.length)];
            // }
            // setRandomString2(randomString2l);
            // for (let i = 0; i < 5; i++) {
            //     randomString3l += letters[Math.floor(Math.random() * letters.length)];
            // }
            // setRandomString3(randomString3l);
            // for (let i = 0; i < 7; i++) {
            //     randomString4l += letters[Math.floor(Math.random() * letters.length)];
            // }
            // setRandomString4(randomString4l);
            // for (let i = 0; i < 6; i++) {
            //     randomString5l += letters[Math.floor(Math.random() * letters.length)];
            // }
            // setRandomString5(randomString5l);
        };
        revivalData();
    },[cateProbIDMy])

    return(
        <div className="problem" key={myAnswerRaw.answerMark}>
            <div className="content-fit">
                <h4>{myAnswerRaw.solvingTime.substring(6,10)}년 {myAnswerRaw.solvingTime.substring(3,5)}월 {myAnswerRaw.solvingTime.substring(0,2)}일 {myAnswerRaw.solvingTime.substring(12,17)}의 풀이</h4>
                <GeoGebraMySolAnimation
                    idIn={problem.problemCode}
                    customToolBarIn={problem.customToolBar}
                    allowStyleBarIn={problem.allowStyleBar}
                    ggbBase64In={problem.problemContent}
                    myPointAnswer={myPointConstruction}
                    myAnswerStep={myConstructionStep}
                    problemType={problem.problemType}
                />
                <div className='horizontal-flex-container'>
                    {isFirst 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/improvemathability/${randomString1}${prevUrlAdd}`, {state:{
                                "categoryCode": categoryCode,
                                "problemCode": prevProblem,
                                "improveAbilityTitle": improveAbilityTitle,
                                "improveAbilityList": improveAbilityList,
                                "improveAbilityType": improveAbilityType
                            }})}
                        >
                            이전 문제
                        </button>
                    } 
                    {isLast 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/improvemathability/${randomString1}${nextUrlAdd}`, {state:{
                                "categoryCode": categoryCode,
                                "problemCode": nextProblem,
                                "improveAbilityTitle": improveAbilityTitle,
                                "improveAbilityList": improveAbilityList,
                                "improveAbilityType": improveAbilityType
                            }})}
                        >
                            다음 문제
                        </button>
                    }
                    <button 
                        className='button'
                        onClick={() => navigateto(`/improvemathability/${cateProbID}`, {state:{
                            "categoryCode": categoryCode,
                            "problemCode": problemCode,
                            "improveAbilityTitle": improveAbilityTitle,
                            "improveAbilityList": improveAbilityList,
                            "improveAbilityType": improveAbilityType
                        }})}
                    >
                        문제로 돌아가기
                    </button>
                    <button 
                        className='button'
                        onClick={() => navigateto(`/improvemathability/my/${cateProbIDMy}`, {state:{
                            "categoryCode": categoryCode,
                            "problemCode": problemCode,
                            "improveAbilityTitle": improveAbilityTitle,
                            "problem": problem,
                            "useStat": useStat,
                            "myAnswerList": myAnswerList,
                            "isFirst": isFirst,
                            "isLast": isLast,
                            "prevProblem": prevProblem,
                            // "prevProblemId": prevProblemId,
                            "prevUrlAdd": prevUrlAdd,
                            "nextProblem": nextProblem,
                            // "nextProblemId": nextProblemId,
                            "nextUrlAdd": nextUrlAdd,
                            "cateProbID": cateProbID,
                            "myConstructionStep": myConstructionStep,
                            "myAnswerRaw":myAnswerRaw,
                            "improveAbilityList": improveAbilityList,
                            "improveAbilityType": improveAbilityType
                        }})}
                    >
                        풀이 다시 선택
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImproveMathAbilityMySolAnimation;
