import { React, useEffect, useState } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams } from 'react-router-dom';
// import '../App.css';
// import GetProblemInfo from '../components/GetProblemInfo';
import axiosapi from "../api/axiosapi";
// import ProblemStatDescription from '../components/ProblemStatDescription';
// import useAuth from '../hooks/useAuth';
// import UserSolvingStat from '../components/UserSolvingStat';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { Helmet } from 'react-helmet';

const DoorBrainUpProblem = ()=> {
    const {brainUpDoorURL}=useParams();
    // const navigateto = useNavigate();
    // const {todayProblemList, todayProblem} = useLocation().state;
    // const location = useLocation();
    // const {auth} = useAuth();
    const [brainUpProblemInfo, setBrainUpProblemInfo] = useState('');
    // const {problem, errP} = GetProblemInfo({"problemID": todayProblem.problemCode});
    // const {useStat} = UserSolvingStat({"problemCode": todayProblem.problemCode});
    // const [isFirst, setIsFirst] = useState(false);
    // const [isLast, setIsLast] = useState(false);
    // const [prevProblem, setPrevProblem] = useState('');
    // const [nextProblem, setNextProblem] = useState('');
    // const [todayProblemListU, setTodayProblemListU] = useState([]);
    // const [imageList, setImageList] = useState([]);
    // const [count, setCount] = useState(0);
    // const [count2, setCount2] = useState(0);
    // const [more, setMore] = useState(0);
    // const [isLoaded, setIsLoaded] = useState(false);
    // const [err, setErr] = useState();
    // const [problemOrder, setProblemOrder] = useState();
    // const [problemOrderU, setProblemOrderU] = useState();
    // const [todayProblemListV, setTodayProblemListV] = useState(todayProblemList);

    useEffect (() => {
        const getBrainUpProblemInfo = async () => {
            try {
                const response = await axiosapi.get('/door/brainupproblem/', {
                    params: {
                        url: brainUpDoorURL
                    },
                    withCredentials: true
                })
                response.data && setBrainUpProblemInfo(response.data);
            } catch (err) {
                console.log(err);
            }
        }

        getBrainUpProblemInfo();
    }, [brainUpDoorURL])

    const splitContent = (sentence) => {
        const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
        const matches = sentence.matchAll(InlineMathRegex);
        let lastIndex = 0;
        const contentArray = [];
      
        for (const match of matches) {
            const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
            const restOfContent = sentence.substring(lastIndex, match.index);
        
            contentArray.push(restOfContent, InlineMathContent);
            lastIndex = match.index + match[0].length;
        }
      
        if (lastIndex < sentence.length) {
            contentArray.push(sentence.substring(lastIndex));
        }
      
        return <div>{contentArray}</div>;
    };

    // useEffect (() => {
    //     todayProblemList.map(prob => {
    //         if (prob.id === todayProblem.id) {
    //             setProblemOrder(prob.problemOrder);
    //             setCount(count+1);
    //         }
    //     })
    // },[brainUpProblemInfo])

    // useEffect (()=> {
    //     const fetchVisitTrialno = async() => {
    //         try {
    //             setUseStat('');
    //             const response = await axiosapi.get('/solve/stat/problem/visit/personal', {
    //                 params: {
    //                     problemCode: problemCode
    //                 }
    //             });
    //             response.data && setUseStat(response.data);
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }
    // },[count])

    // const getMoreTodayProblemList = async () => {
    //     try {
    //         const response = await axiosapi.get('/home/todayproblem/more/', {
    //             params: {
    //                 id: todayProblem.id,
    //                 problemCode: todayProblem.problemCode
    //             }
    //         });
    //         response.data && setTodayProblemListU(response.data.todayProblemList);
    //         response.data && response.data.imageList.length > 0 && setImageList(response.data.imageList);
    //         setIsLoaded(true);
    //         response.data && response.data.todayProblemList.map(prob => {
    //             if (prob.id === todayProblem.id) {
    //                 {
    //                     setProblemOrderU(prob.problemOrder);
    //                     setCount2(count2+1);
    //                 }
    //             }
    //         })
    //     } catch(err) {
    //         setErr(err);
    //         if (err.response) {
    //             console.log(err.response.data);
    //             console.log(err.response.status);
    //             console.log(err.response.headers);
    //         } else {
    //             console.log(`Error: ${err.message}`);
    //         }
    //     }
    // }

    // useEffect (() => {
    //     getMoreTodayProblemList();
    // },[more])

    // useEffect (() => {
    //     if (problemOrderU === 1) {
    //         setIsFirst(true);
    //         setPrevProblem(todayProblem);
    //     }
    //     else if (todayProblemListU.length === problemOrderU) {
    //         setIsLast(true);
    //         setNextProblem(todayProblem);
    //     }
    //     else {
    //         setIsFirst(false);
    //         setIsLast(false);
    //         todayProblemListU.map(prob => {
    //             if (prob.problemOrder === problemOrderU-1) {
    //                 setPrevProblem(prob);
    //             }
    //             if (prob.problemOrder === problemOrderU+1) {
    //                 setNextProblem(prob);
    //             }
    //         })
    //     }
    // },[count2])

    // useEffect(() => {
    //     setTodayProblemListV(todayProblemListU);
    // },[todayProblemListU])

    return(
        <div className="problem" key={brainUpDoorURL}>
            <Helmet>
                <title>{brainUpProblemInfo.problemTitle && splitContent(brainUpProblemInfo.problemTitle)}</title>
                <meta name="description" content={brainUpProblemInfo.problemDescription  && splitContent(brainUpProblemInfo.problemDescription)} />
                <meta property="og:title" content={brainUpProblemInfo.problemTitle && splitContent(brainUpProblemInfo.problemTitle)} />
                <meta property="og:description" content={brainUpProblemInfo.problemDescription  && splitContent(brainUpProblemInfo.problemDescription)} />
                {/* <meta property="og:url" content="http://www.example.com/mypage" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="http://www.example.com/image.jpg" /> */}
            </Helmet>
            <div className="content-fit">
                <h5 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px"}}>{brainUpProblemInfo.brainUpTitle}</h5>
                {<div style={{fontSize:24,fontWeight:900, marginTop:"20px", marginBottom:"25px"}}>
                    <div>
                        {brainUpProblemInfo.problemTitle && splitContent(brainUpProblemInfo.problemTitle)}
                    </div>
                </div>}
                {<div style={{marginLeft:"5px"}}>
                    {brainUpProblemInfo.problemDescription  && splitContent(brainUpProblemInfo.problemDescription)}

                </div>}
                {brainUpProblemInfo.problemType != 3 && <div style={{marginLeft:"5px"}}>
                    {brainUpProblemInfo.triedUsersNum === 0
                        ? "아무도 도전한 사람이 없습니다. 문제를 푸는 첫 번째 도전자가 되세요."
                        : brainUpProblemInfo.successfulUserNum === 0 
                            ? `${brainUpProblemInfo.triedUsersNum}명이 도전했으나 아직 문제를 풀어낸 사람이 아직 없습니다. 문제를 푸는 첫 번째 사용자가 되시기 바랍니다.`
                            : `${brainUpProblemInfo.triedUsersNum}명이 도전해 ${brainUpProblemInfo.successfulUserNum}명이 문제를 풀었습니다. 문제를 풀어낸 ${(brainUpProblemInfo.successfulUserNum/brainUpProblemInfo.triedUsersNum*100).toFixed(0)}%의 사용자와 같은 자리를 차지하세요.`
                    }
                </div>}

                {/* <p style={{marginLeft:"5px"}}>{brainUpProblemInfo.publicationDate}</p> */}
            
                {brainUpProblemInfo && <GeoGebraP
                    title={brainUpProblemInfo.problemTitle}
                    idIn={brainUpProblemInfo.problemCode}
                    customToolBarIn={brainUpProblemInfo.customToolBar}
                    allowStyleBarIn={brainUpProblemInfo.allowStyleBar}
                    ggbBase64In={brainUpProblemInfo.problemContent}
                    problemType={brainUpProblemInfo.problemType}
                    showToolBarHelpIn={brainUpProblemInfo.showToolBarHelp}
                    enableLabelDragIn={brainUpProblemInfo.enableLabelDrag}
                    enableShiftDragZoomIn={brainUpProblemInfo.enableShiftDragZoom}
                />}
            
                {/* <div className='problemmove'>
                    {(problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 6 || problem.problemType === 7)
                        ? <button
                                className='button'
                                onClick={
                                    () => auth?.accessToken
                                        ? navigateto(`/todayproblem/my/s/list`, {state:{
                                            "todayProblemList": todayProblemListV,
                                            "problem": problem,
                                            "todayProblem": todayProblem,
                                            "useStat": useStat,
                                            "isFirst": isFirst,
                                            "isLast": isLast,
                                            "prevProblem": prevProblem,
                                            "nextProblem": nextProblem,
                                            "proID": proID
                                        }})
                                        : navigateto("/login", {state:{"from": location}}, {replace: true})
                                }
                            >
                                내 풀이보기
                            </button>
                        : null
                    }

                    <button
                        className='button'
                        onClick={
                            () => auth?.accessToken
                                ? navigateto(`/todayproblem/image/list/`, {state:{
                                    "todayProblemList": todayProblemListV,
                                    "todayProblem": todayProblem,
                                    "problem":problem
                                }})
                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                        }
                    >
                        그림보기
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default DoorBrainUpProblem;
