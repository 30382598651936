import React from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import './mathsubject.css';
import ForStudentMenu from "../components/ForStudentMenu";
import ViewMyAnswerList from "../components/ViewMyAnswerList";

const StudentMyAnswerList = () => {
       return (
              <div className="App-full-row-container">
                     <div className="left-menu-box-1">
				<div className="subjectsidebar-4" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
					<ForStudentMenu />
				</div>
			</div>
                     <div className="content-box-1" style={{marginTop:"14px"}}>
                            <ViewMyAnswerList />
                     </div>
              </div>
       )
}

export default StudentMyAnswerList;