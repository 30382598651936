import { useEffect, useState } from "react";
import axiosapi from "../api/axiosapi";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const UserSolvingStat = ({problemCode}) => {
    const [useStat, setUseStat] = useState('')
    const {auth} = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const fetchTrialno = async() => {
        let isMounted = true;
		const controller = new AbortController();

        try {
            setUseStat('');
            const response = await axiosPrivate.get('/solve/stat/problem/personal', {
                params: {
                    problemCode: problemCode
                },
                signal: controller.signal
            });
            isMounted && response.data && setUseStat(response.data);
        } catch (err) {
            console.log(err);
        }

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    const fetchVisitTrialno = async() => {
        try {
            setUseStat('');
            const response = await axiosapi.get('/solve/stat/problem/visit/personal', {
                params: {
                    problemCode: problemCode
                }
            });
            response.data && setUseStat(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        auth?.accessToken ? fetchTrialno() : fetchVisitTrialno();
    },[problemCode])

    return {useStat}
}

export default UserSolvingStat;