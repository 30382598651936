import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return(
        <div>
            <h1>사용 권한이 없습니다.</h1>
            <h3>사용할 수 없는 페이지입니다.</h3>
            <div>
                <button onClick={goBack}>돌아가기</button>
            </div>
        </div>
    )
}

export default Unauthorized;