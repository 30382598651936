import React, { useState, useEffect } from "react";
// import "./home.css"
import axiosapi from "../api/axiosapi";
import { Link } from "react-router-dom";
// import SideMenu from "../components/SideMenu";
import { useLocation } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import Footer from "../components/Footer";

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const TodayProblemImage = () => {
    const {todayProblem} = useLocation().state;
    const [todayProblemList, setTodayProblemList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [errPT, setErrPT] = useState();

    useEffect(() => {
        const controller = new AbortController();

        const fetchTodayProblem = async () => {
            try {
                const response = await axiosapi.get('/home/todayproblem/image/', {
                    params: {
                        id: todayProblem.id,
                        problemCode: todayProblem.problemCode
                    }
                })
                response.data && setTodayProblemList(response.data.todayProblemList);
                response.data && response.data.imageList.length > 0 && setImageList(response.data.imageList);
            } catch (err) {
                setErrPT(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchTodayProblem();

        return () => controller.abort();
    },[todayProblem])

    return(
        <div className="App-full-container">
            <div className="position-center-box-2-1">
                <h4 style={{color:"sienna", fontWeight:"bold"}}>즐거운 작도 세상</h4>
                <p>작도 문제를 해결하고 숨겨진 생각을 그림으로 발산하세요. 솔직하고 어설픈 그림도 크게 들린답니다.</p>
                <Link 
                    to={`/todayproblemfi/${todayProblem.urlCode}`}
                    state={{
                        "todayProblem": todayProblem
                    }}
                >
                    <div style={{textAlign:"center"}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                    <img src={`/image/${todayProblem.imageFile}`} style={{width:'100%', backgroundColor:`${todayProblem.backgroundColor}`}} />
                </Link>
            </div>
            <div className="position-center-box-2-1">
                <h5 style={{marginTop:'25px', marginBottom:'18px', marginLeft:'8px', fontWeight:"bold"}}>그림이 와글와글</h5>
                {imageList.map(image => {
                    return(
                        <div key={image.id}>
                            <Link 
                                to={`/viewimage/${image.imageFile}/${image.backgroundColor}`}
                                target="_blank"
                            >
                                <p style={{marginLeft:'10px'}}>{image.coloringTitle}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>
            <div className="position-center-box-2-1">
                <h5 style={{marginTop:'25px', marginBottom:'18px', fontWeight:"bold"}}>다른 작도 문제</h5>
                {todayProblemList.slice(1,todayProblemList.length).map(todayProblem => {
                    return (
                        <div key={todayProblem.id}>
                            <Link 
                                to={`/todayproblem/image/${todayProblem.urlCode}`}
                                state={{
                                    "todayProblem": todayProblem
                                }}
                            >
                                <div style={{marginLeft:'2px'}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                            </Link>
                        </div>
                    )
                })}
            </div>
            {/* <div className="boxHome-10">
                <div>
                    <SideMenu />
                </div>
            </div> */}
            <div style={{width:"100%"}}>
                <Footer/>
            </div>
        </div>
    );
}

export default TodayProblemImage;
