import React from "react";
// import './mathsubject.css';
import ForStudentMenu from "../components/ForStudentMenu";
import ChanegePassword from "../components/ChangePassword";

const StudentChangePassword = () => {
       return (
              <div className="App-full-row-container">
                     <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                            <ForStudentMenu />
                     </div>
                     <div className="content-box-1">
                            <div>
                                   <ChanegePassword />
                            </div>
                     </div>
              </div>
       )
}

export default StudentChangePassword;