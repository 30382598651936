import React, {useState} from "react";
// import './login.css';
// import '../App.css';
import axiosapi from "../api/axiosapi";
import { useNavigate, useLocation } from "react-router-dom";

const ForgotIdThird = () => {
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    // const [mobile, setMobile] = useState('');
    // const [mobileCompany, setMobileCompany] = useState('');
    // const [newMobileCompany, setNewMobileCompany] = useState('');
    // const [newMobile, setNewMobile] = useState('');
    const navigateto = useNavigate();
    const [errMsg, setErrMsg] = useState();
    // const [checkData, setCheckData] = useState('');
    const bridgeway = location.state.bridgeway;

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        const data_email = {
            name: name,
            email: email
        }

        try {
            const response = await axiosapi.post('/user/find/username/sendbyemail', data_email)
            response.data && alert(`${response.data.message}`)
            if (response.data.code === 200) navigateto(`/login`)
        } catch (err) {
            setErrMsg(err.message);
            console.log(err.message);
        }
    }

    // const handleRegisterMobileSubmit = async (e) => {
    //     e.preventDefault();

    //     const data_mobile = {
    //         name: name,
    //         mobile: mobile
    //     }

    //     try {
    //         const response = await axiosapi.post('/user/find/username/sendbymobile', data_mobile)
    //         response.data && alert(`${mobile}로 아이디를 발송했습니다.`)
    //         navigateto(`/login`)
    //     } catch (err) {
    //         setErrMsg(err.message);
    //         console.log(err.message);
    //     }
    // }

    // const handleNewMobileSubmit = async (e) => {
    //     e.preventDefault();

    //     const data_mobile = {
    //         name: name,
    //         mobile: mobile
    //     }

    //     try {
    //         const response = await axiosapi.post('/user/find/username/sendbynewmobile', data_mobile)
    //         response.data && alert(`${newMobile}로 아이디를 발송했습니다.`)
    //         navigateto(`/login`)
    //     } catch (err) {
    //         setErrMsg(err.message);
    //         console.log(err.message);
    //     }
    // }

    // const confirmMobileUser = async (e) => {
    //     e.preventDefault();
    //     try {} catch (err) {}
    // }

    return(
        <div className="App-full-screen-container">
            <div className="position-center-box-1-1">
                <div>
                    <p>아이디를 받고 싶은 방법의 탭을 선택하여 필요한 정보를 다시 입력하고 발송 요청을 하세요.</p>
                </div>
                <div>
                    {bridgeway === 1
                        ?   (<div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="resgisteredemail-tab" data-bs-toggle="tab" data-bs-target="#resgisteredemail" type="button" role="tab" aria-controls="resgisteredemail" aria-selected="true">등록한 이메일</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">등록한 휴대폰</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="newmobile-tab" data-bs-toggle="tab" data-bs-target="#newmobile" type="button" role="tab" aria-controls="newmobile" aria-selected="false">새로운 휴대폰</button>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="resgisteredemail" role="tabpanel" aria-labelledby="home-tab">
                                        <div>
                                            <form onSubmit={handleEmailSubmit}>
                                                <div className="vertical-flex-container">
                                                    <div className="width-100-percent-box">
                                                        <div style={{marginBottom:"7px", marginTop:"25px"}}>
                                                            <label htmlFor="name">이름:</label>
                                                        </div>
                                                        <div>
                                                            <input 
                                                                type="text" 
                                                                id="name" 
                                                                value={name} 
                                                                placeholder="registered name" 
                                                                style={{width:"100%"}}
                                                                onChange={e => setName(e.target.value)} 
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="width-100-percent-box">
                                                        <div style={{marginBottom:"7px", marginTop:"25px"}}>
                                                            <label htmlFor="email">등록한 이메일 주소:</label>
                                                        </div>
                                                        <div>
                                                            <input 
                                                                type="email" 
                                                                id="email" 
                                                                value={email} 
                                                                placeholder="registered email address" 
                                                                style={{width:"100%"}}
                                                                onChange={e => setEmail(e.target.value)} 
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="width-100-percent-box" style={{textAlign:"center"}}>
                                                        <button className="button">이메일로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div>
                                            <form onSubmit={handleRegisterMobileSubmit}>
                                                <div className="inputListContainer">
                                                    <div className="labeledItem">
                                                        <label htmlFor="name">이름:</label>
                                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="mobilecompany">등록한 휴대폰의 통신사:</label>
                                                        <select id="mobilecompony" value={mobileCompany} onChange={e => setMobileCompany(e.target.value)}>
                                                            <option value>통신사를 선택하세요.</option>
                                                            <option value="skt">SKT</option>
                                                            <option value="kt">KT</option>
                                                            <option value="lgt">LGT</option>
                                                            <option value="sktcheap">SKT 알뜰폰</option>
                                                            <option value="ktcheap">KT 알뜰폰</option>
                                                            <option value="lgtcheap">LGT 알뜰폰</option>
                                                        </select>
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="mobile">등록한 휴대폰 번호:</label>
                                                        <input type="phone" id="mobile" value={mobile} placeholder="registered mobile number" onChange={e => setMobile(e.target.value)} required />
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                        <button className="button" style={{width:"230px", padding:"7px 15px"}}>휴대폰으로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="newmobile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div>
                                            <form onSubmit={handleNewMobileSubmit}>
                                                <div className="inputListContainer">
                                                    <div className="labeledItem">
                                                        <label htmlFor="name">이름:</label>
                                                        <input type="text" id="name" value={name} placeholder="name" onChange={e => setName(e.target.value)} required />
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="newmobilecompany">통신사:</label>
                                                        <select id="newmobilecompony" value={newMobileCompany} onChange={e => setNewMobileCompany(e.target.value)}>
                                                            <option value>통신사를 선택하세요.</option>
                                                            <option value="skt">SKT</option>
                                                            <option value="kt">KT</option>
                                                            <option value="lgt">LGT</option>
                                                            <option value="sktcheap">SKT 알뜰폰</option>
                                                            <option value="ktcheap">KT 알뜰폰</option>
                                                            <option value="lgtcheap">LGT 알뜰폰</option>
                                                        </select>
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="newmobile">휴대폰 번호:</label>
                                                        <input type="phone" id="newmobile" value={newMobile} placeholder="mobile number" onChange={e => setNewMobile(e.target.value)} required />
                                                        <button className="btn btn-secondary" type="button" onClick={confirmMobileUser}>인증</button>
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                        <button className="button" style={{width:"230px", padding:"7px 15px"}}>새 휴대폰으로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )
                        :   (<div>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="resgisteredemail-tab" data-bs-toggle="tab" data-bs-target="#resgisteredemail" type="button" role="tab" aria-controls="resgisteredemail" aria-selected="true">등록한 이메일</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">등록한 휴대폰</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="newmobile-tab" data-bs-toggle="tab" data-bs-target="#newmobile" type="button" role="tab" aria-controls="newmobile" aria-selected="false">새로운 휴대폰</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade" id="resgisteredemail" role="tabpanel" aria-labelledby="home-tab">
                                        <div>
                                            <form onSubmit={handleEmailSubmit}>
                                            <div  className="vertical-flex-container">
                                                    <div className="width-100-percent-box">
                                                        <div style={{marginBottom:"7px", marginTop:"25px"}}>
                                                            <label htmlFor="name">이름:</label>
                                                        </div>
                                                        <div>
                                                            <input 
                                                                type="text" 
                                                                id="name" 
                                                                value={name} 
                                                                placeholder="registered name" 
                                                                style={{width:"100%"}}
                                                                onChange={e => setName(e.target.value)} 
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="width-100-percent-box">
                                                        <div style={{marginBottom:"7px", marginTop:"25px"}}>
                                                            <label htmlFor="email">등록한 이메일 주소:</label>
                                                        </div>
                                                        <div>
                                                            <input 
                                                                type="email" 
                                                                id="email" 
                                                                value={email} 
                                                                placeholder="registered email address" 
                                                                style={{width:"100%"}}
                                                                onChange={e => setEmail(e.target.value)} 
                                                                required 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="width-100-percent-box" style={{textAlign:"center"}}>
                                                        <button className="button">이메일로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade show active" id="mobile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div>
                                            <form onSubmit={handleRegisterMobileSubmit}>
                                                <div className="inputListContainer">
                                                    <div className="labeledItem">
                                                        <label htmlFor="name">이름:</label>
                                                        <input type="text" id="name" value={name} placeholder="registered name" onChange={e => setName(e.target.value)} required />
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="mobilecompany">등록한 휴대폰의 통신사:</label>
                                                        <select id="mobilecompony" value={mobileCompany} onChange={e => setMobileCompany(e.target.value)}>
                                                            <option value>통신사를 선택하세요.</option>
                                                            <option value="skt">SKT</option>
                                                            <option value="kt">KT</option>
                                                            <option value="lgt">LGT</option>
                                                            <option value="sktcheap">SKT 알뜰폰</option>
                                                            <option value="ktcheap">KT 알뜰폰</option>
                                                            <option value="lgtcheap">LGT 알뜰폰</option>
                                                        </select>
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="mobile">등록한 휴대폰 번호:</label>
                                                        <input type="phone" id="mobile" value={mobile} placeholder="registered mobile number" onChange={e => setMobile(e.target.value)} required />
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                        <button className="button" style={{width:"230px", padding:"7px 15px"}}>휴대폰으로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="newmobile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div>
                                            <form onSubmit={handleNewMobileSubmit}>
                                                <div className="inputListContainer">
                                                    <div className="labeledItem">
                                                        <label htmlFor="name">이름:</label>
                                                        <input type="text" id="name" value={name} placeholder="name" onChange={e => setName(e.target.value)} required />
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="newmobilecompany">통신사:</label>
                                                        <select id="newmobilecompony" value={newMobileCompany} onChange={e => setNewMobileCompany(e.target.value)}>
                                                            <option value>통신사를 선택하세요.</option>
                                                            <option value="skt">SKT</option>
                                                            <option value="kt">KT</option>
                                                            <option value="lgt">LGT</option>
                                                            <option value="sktcheap">SKT 알뜰폰</option>
                                                            <option value="ktcheap">KT 알뜰폰</option>
                                                            <option value="lgtcheap">LGT 알뜰폰</option>
                                                        </select>
                                                    </div>
                                                    <div className="labeledItem">
                                                        <label htmlFor="newmobile">휴대폰 번호:</label>
                                                        <input type="phone" id="newmobile" value={newMobile} placeholder="mobile number" onChange={e => setNewMobile(e.target.value)} required />
                                                        <button className="btn btn-secondary" type="button" onClick={confirmMobileUser}>인증</button>
                                                    </div>
                                                    <div style={{textAlign:"center"}}>
                                                        <button className="button" style={{width:"230px", padding:"7px 15px"}}>새 휴대폰으로 아이디 받기</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotIdThird;