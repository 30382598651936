import React, { useEffect, useState } from 'react';
import GeoGebraMySol from '../components/GeoGebraMySol';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
import ViewMyAnswerItem from '../components/ViewMyAnswerItem';

const TodayProblemMy = ()=> {
    const {proIDMy}=useParams();
    const navigateto = useNavigate();
    const {todayProblemList, problem, todayProblem, useStat, myAnswerList, isFirst, isLast, prevProblem, nextProblem, proID} = useLocation().state;
    const {myConstructionStep, myAnswerRaw, myPointConstruction, errAns} = ViewMyAnswerItem({"answerMark": proIDMy});
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    const location = useLocation();
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [myConstruct, setMyConstruct] = useState('');
    const [flag, setFlag] =useState('');

    useEffect(() => {
        setMyConstruct(myConstructionStep);
        setFlag(proIDMy);
    },[myConstructionStep])

    // useEffect(() => {
    //     const revivalData = () => {
    //         const letters = '1234567890';
    //         let randomString1l = '';
    //         let randomString2l = '';
    //         let randomString3l = '';
    //         let randomString4l = '';
    //         let randomString5l = '';
    //         for (let i = 0; i < 3; i++) {
    //             randomString1l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString1(randomString1l); 
    //         for (let i = 0; i < 8; i++) {
    //             randomString2l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString2(randomString2l);
    //         for (let i = 0; i < 5; i++) {
    //             randomString3l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString3(randomString3l);
    //         for (let i = 0; i < 7; i++) {
    //             randomString4l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString4(randomString4l);
    //         for (let i = 0; i < 6; i++) {
    //             randomString5l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString5(randomString5l);
    //     };
    //     revivalData();
    // },[proIDMy])

    return(
        <div className="problem" key={myAnswerRaw.answerMark}>
            {errAns 
                ? navigateto("/login", {state:{"from": location}}, {replace: true})
                : <div className="content-fit">
                    <h4>{myAnswerRaw && `${myAnswerRaw.solvingTime.substring(6,10)}년 ${myAnswerRaw.solvingTime.substring(3,5)}월 ${myAnswerRaw.solvingTime.substring(0,2)}일 ${myAnswerRaw.solvingTime.substring(12,17)}의 풀이`}</h4>
                    <div>
                        <select value={selectedAnswer} style={{height:"36px"}} onChange={(e) => setSelectedAnswer(e.target.value)}>
                            <option value>문제를 푼 때를 선택하세요.</option>
                            {myAnswerList.map(myAns => {
                                return <option value={myAns.answerMark} key={myAns.answerMark}>
                                    {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                                </option>
                            })}
                        </select>
                        <button 
                            className='button' 
                            style={{marginLeft:"10px"}}
                            onClick={() => navigateto(`/todayproblem/my/${selectedAnswer}`, {state: {
                                "todayProblemList": todayProblemList,
                                "problem": problem,
                                "todayProblem": todayProblem,
                                "myAnswerList": myAnswerList,
                                "useStat": useStat,
                                "isFirst": isFirst,
                                "isLast": isLast,
                                "prevProblem": prevProblem,
                                "nextProblem": nextProblem,
                                "proID": proID
                            }})}
                        >
                            풀이 보기
                        </button>
                    </div>
                    <div key={flag}>
                        {myConstruct.length !== 0 
                            ? <GeoGebraMySol
                                    idIn={problem.problemCode}
                                    customToolBarIn={problem.customToolBar}
                                    allowStyleBarIn={problem.allowStyleBar}
                                    ggbBase64In={problem.problemContent}
                                    myAnswerStep={myConstruct}
                                    myPointAnswer={myPointConstruction}
                                    problemType={problem.problemType}
                                />
                            : "loading"
                        }
                    </div>
                    <div className='horizontal-flex-container'>
                        {isFirst 
                            ? null 
                            : <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${prevProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "todayProblem": prevProblem
                                    }})}
                                >
                                    이전 문제
                                </button>
                        } 
                        {isLast 
                            ? null 
                            : <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/${nextProblem.urlCode}`, {state:{
                                        "todayProblemList": todayProblemList, 
                                        "todayProblem": nextProblem
                                    }})}
                                >
                                    다음 문제
                                </button>
                        }
                        <button 
                            className='button'
                            onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                                "todayProblemList": todayProblemList,
                                "todayProblem": todayProblem
                            }})}
                        >
                            문제로 돌아가기
                        </button>
                        {(problem.problemType === 1 || problem.problemType === 2)
                            ? <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/my/animation/${myAnswerRaw.answerMark}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "useStat": useStat,
                                        "myAnswerList": myAnswerList,
                                        "myConstructionStep": myConstructionStep,
                                        "myPointConstruction": myPointConstruction,
                                        "problem": problem,
                                        "todayProblem": todayProblem,
                                        "myAnswerRaw": myAnswerRaw,
                                        "isFirst": isFirst,
                                        "isLast": isLast,
                                        "prevProblem": prevProblem,
                                        "nextProblem": nextProblem,
                                        "proID": proID
                                    }})}
                                >
                                    풀이 과정 보기
                                </button>
                            : null
                        }
                        {(problem.problemType === 1 || problem.problemType === 2)
                            ? <button 
                                    className='button'
                                    onClick={() => navigateto(`/todayproblem/my/design/${myAnswerRaw.answerMark}`, {state:{
                                        "todayProblemList": todayProblemList,
                                        "useStat": useStat,
                                        "myAnswerList": myAnswerList,
                                        "myConstructionStep": myConstructionStep,
                                        "myPointConstruction": myPointConstruction,
                                        "problem": problem,
                                        "todayProblem": todayProblem,
                                        "myAnswerRaw": myAnswerRaw,
                                        "isFirst": isFirst,
                                        "isLast": isLast,
                                        "prevProblem": prevProblem,
                                        "nextProblem": nextProblem,
                                        "proID": proID
                                    }})}
                                >
                                    멋있게 꾸며서 자랑하기
                                </button>
                            : null
                        }
                    </div>
                </div>
            }
            <br/>
        </div>
    );
};

export default TodayProblemMy;