import { useState, useEffect } from "react";
import axiosapi from "../api/axiosapi";

const GetCanvasInfo = ({canvasCode}) => {
    const [canvas, setCanvas] = useState('');
    const [count, setCount] = useState(0);
    const [errP, setErrP] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        // 메모리 관리를 위해 useEffect를 관리하는 함수
        const controller = new AbortController();

        setErrP(false);

        // 캔버스를 서버에서 가져오는 함수
        async function fetchCanvas () {
            try {
                const response = await axiosapi.get('/canvas/'+canvasCode);
                // 위 링크는 임시로 사용하는 링크입니다. 본 서비스에서는 링크 조정이 필요
                // const response = await axiosapi.get('/problem?topCategory='+topCategory+'&idIn='+problemID);
                response.data && setCanvas(response.data);
                // console.log(response.data);
                response.data && setCount(count+1);
            } catch (e) {
                setErrP(true);
                if (e.response) {
                    console.log(e.response.data);
                    console.log(e.response.status);
                    console.log(e.response.headers);
                } else {
                    console.log(`Error: ${e.message}`);
                }
            } finally {
                setLoading(false);
            }
        };
        
        fetchCanvas();

        // useEffect를 클린업하는 함수
        return () => controller.abort();
    },[canvasCode]);

    return {canvas, errP}
}

export default GetCanvasInfo;