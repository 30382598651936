import axiosapi from "../api/axiosapi";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const GradeFirstProblem = () => {
    const [contents, setContents] = useState('');
    const {topCategory} =useParams();
    const navigateTo = useNavigate();
    const {topCategoryTitle} = useLocation().state;

    useEffect( () => {
        const controller = new AbortController();

        const fetchSubjectList = async () => {
            try {
                const response = await axiosapi.get('/subjectList', {state: {topCategory: topCategory}})
                response.data && setContents(response.data);
            } catch (err) {
                console.log(err)
            }
        }

        fetchSubjectList();
        
        return () => controller.abort();
    },[topCategory])

    return(
        <div>
            {contents && contents.slice(0,1).map(content => {
                return(
                    <div key={topCategory}>
                        {navigateTo(`/mathsubject/${content.categories[0].problems[0].idIn}`, {state: {
                            "problemTopCategory": content.topCategory,
                            "topCategoryTitle": topCategoryTitle
                        }})}
                    </div>
                )
            })}
        </div>
    )
}

export default GradeFirstProblem;