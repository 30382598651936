import { useEffect, useState } from 'react'
import axiosapi from '../api/axiosapi'
import axios from 'axios'

const useGetSubjectList = (pageNumber) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [subjectList, setSubjectList] = useState([])

    useEffect (() => {
        setLoading(true)
        setError(false)
        let cancel
        axiosapi({
            method: 'GET',
            url: '/home/goodapproach/',
            params: { page: pageNumber },
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then (res => {
            setSubjectList(prevSubjectList => {
                return [...new Set([...prevSubjectList, ...res.data.map(p => p)])]
            })
            setHasMore(res.data.length > 0)
            setLoading(false)
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    },[pageNumber])

    return { loading, error, hasMore, subjectList }
}

export default useGetSubjectList;
