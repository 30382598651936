import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
// import { VscCheck, VscRocket } from "react-icons/vsc";
// import '../pages/mathsubject.css';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

function SideMenuCategory ({category, problemCate, problemID, topCategory, topCategoryTitle, isSample}) {
    const [open, setOpen] = useState(false);
    const [randomString, setRandomString] = useState();

    useEffect(() => {
        const randomAlphabetString = () => {
            const letters = '1234567890';
            let randomStringl = '';
            for (let i = 0; i < 39; i++) {
                randomStringl += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString(randomStringl);
          };
          randomAlphabetString();
    },[])

    function ProblemLinkC ({LinkTitle, LinkId}) {
        return(
            <div>
                {LinkId === problemID
                    // ? <div style ={{color:'red', fontWeight: 'bold'}}>{LinkTitle} <VscCheck/> <VscRocket/></div>
                    ? <div style ={{color:'red', fontWeight: 'bold'}}>{LinkTitle}</div>
                    : isSample
                        ? <Link to={`/mathsubject/sample/${randomString}`} state={{
                                "subjectCode": topCategory,
                                "problemCode": LinkId,
                                "subjectTitle": topCategoryTitle
                            }}>
                                {LinkTitle}
                        </Link>
                        : <Link to={`/mathsubject/${randomString}`} state={{
                                "subjectCode": topCategory,
                                "problemCode": LinkId,
                                "subjectTitle": topCategoryTitle
                            }}>
                                {LinkTitle}
                        </Link>
                }
            </div>
        )
    }

    function ProblemLink ({LinkTitle, LinkId}) {
        return(
            <div>
                {isSample
                    ? <Link to={`/mathsubject/sample/${randomString}`} state={{
                            "subjectCode": topCategory,
                            "problemCode": LinkId,
                            "subjectTitle": topCategoryTitle
                        }}>
                            {LinkTitle}
                    </Link>
                    : <Link to={`/mathsubject/${randomString}`} state={{
                            "subjectCode": topCategory,
                            "problemCode": LinkId,
                            "subjectTitle": topCategoryTitle
                        }}>
                            {LinkTitle}
                    </Link>
                }
            </div>
        )
    }

    return(
        <div>
            <div className="subject-sidebar-2" style={{cursor: 'pointer'}}  onClick={()=>setOpen(!open)}>
                <b>{category.categoryTitle && splitContent(category.categoryTitle)}</b>
            </div>
            <div className="subject-sidebar-3">
                {
                    category.problems.map(problem => {
                        return(
                            <div key={problem.problemCode}>
                                {category.categoryCode === problemCate 
                                    ? <ProblemLinkC LinkTitle={problem.problemTitle && splitContent(problem.problemTitle)} LinkId={problem.problemCode} /> 
                                    : (open ? <ProblemLink LinkTitle={problem.problemTitle && splitContent(problem.problemTitle)} LinkId={problem.problemCode} /> : null)
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

export default SideMenuCategory;