import React from "react";
import { useParams } from 'react-router-dom';

const ViewImage = () => {
    const {imageFileName}=useParams();
    const {imageBackgroundColor}=useParams();

    console.log(imageFileName);
    console.log(imageBackgroundColor);
    return (
        <div className="App-full-screen-container">
            <img src={`/image/${imageFileName}`} style={{backgroundColor:`${imageBackgroundColor}`}} />
        </div>
    );
}

export default ViewImage;