import axiosapi from '../api/axiosapi';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        try {
            const response = await axiosapi.post('/refresh-token', {
                withCredentials: true
            });
            setAuth(prev => {
                // console.log(JSON.stringify(prev));
                // console.log(response.data.access_token);
                return { 
                    ...prev, 
                    userRole1: response.data.userRole1,
                    userRole2: response.data.userRole2,
                    accessToken: response.data.access_token 
                }
            });
            return response.data.access_token;
        } catch (err) {
            setAuth(prev => {
                // console.log(JSON.stringify(prev));
                // console.log(response.data.access_token);
                return { 
                    ...prev, 
                    accessToken: 'notoken' 
                }
            });
            return 'notoken';
        }
    }
    return refresh;
};

export default useRefreshToken;