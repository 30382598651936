import React, {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
// import './mathsubject.css';
// import '../App.css';
import ViewMyAnswer from '../components/ViewMyAnswer';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }

    contentArray.push("의 풀이 조회");
  
    return <div>{contentArray}</div>;
};

const MathSubjectMyS = ()=> {
    const {subjectCode, problemCode, subjectTitle, subject, problem, problemCategory, useStat, firstProbID, isSample} = useLocation().state;
    const {isMyAnswer, latestAnswer, myAnswerList, errMyAns} = ViewMyAnswer({"problemCode": problemCode});
    const navigateto = useNavigate();
    const [selectedAnswer, setSelectedAnswer] = useState();
    const location = useLocation();
    // const [myConstruct, setMyConstruct] = useState('');
    // const [flag, setFlag] =useState('');

    if (isMyAnswer === false) {
        return(
            errMyAns 
                ? navigateto("/login", {state:{"from": location}}, {replace: true})
                : <div className="App-full-row-container" key={problem.problemCode}>
                    <div className="left-menu-box-1">
                        <SideBar 
                            topCategory={subjectCode}
                            problemCate={problemCategory} 
                            categories={subject.categories}
                            problemID={problem.problemCode}
                            topCategoryTitle={subject.subjectTitle}
                            problemId={problem.problemCode}
                            isSample={isSample}
                        />
                    </div>
                    <div className="content-box-1">
                        <div className="problem">
                            <div style={{marginTop:"30px"}}>
                                <p>"문제를 푼 기록이 없습니다."</p>
                                <button 
                                    className='button'
                                    onClick={() => isSample ? navigateto(`/mathsubject/sample/${firstProbID}`, {state:{
                                            "subjectCode": subjectCode,
                                            "problemCode": problemCode,
                                            "subjectTitle": subjectTitle}})
                                        : navigateto(`/mathsubject/${firstProbID}`, {state:{
                                            "subjectCode": subjectCode,
                                            "problemCode": problemCode,
                                            "subjectTitle": subjectTitle
                                        }})
                                    }
                                >
                                    문제로 돌아가기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    } 

    return(
        <div className="App-full-row-container" key={problem.problemCode}>
            <div className="left-menu-box-1">
                <SideBar 
                    topCategory={subjectCode}
                    problemCate={problemCategory} 
                    categories={subject.categories}
                    problemID={problem.problemCode}
                    topCategoryTitle={subject.subjectTitle}
                    problemId={problem.problemCode}
                    isSample={isSample}
                />
            </div>
            <div className="content-box-1">
                <div className="problem"  key={problemCode}>
                    <div style={{fontSize:24,fontWeight:900, marginTop:"30px", marginBottom:"25px"}}>{splitContent(problem.problemTitle)}</div>
                    <div>
                        <select value={selectedAnswer} style={{height:"36px"}} onChange={(e) => setSelectedAnswer(e.target.value)}>
                            <option value>문제를 푼 때를 선택하세요.</option>
                            {myAnswerList.map(myAns => {
                                return <option value={myAns.answerMark} key={myAns.answerMark}>
                                    {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                                </option>
                            })}
                        </select>
                        <button 
                            className='button' 
                            style={{marginLeft:"10px"}}
                            onClick={() => navigateto(`/mathsubject/my/${selectedAnswer}`, {state: {
                                "subjectCode": subjectCode,
                                "problemCode": problemCode,
                                "subjectTitle": subjectTitle,
                                "subject":subject,
                                "problem": problem,
                                "problemCategory": problemCategory,
                                "useStat": useStat,
                                "myAnswerList": myAnswerList,
                                "firstProbID": firstProbID,
                                "isSample": isSample
                            }})}
                        >
                            풀이 보기
                        </button>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default MathSubjectMyS;