import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
// import '../pages/signup.css';

const ChangePassword = () => {
       const axiosPrivate = useAxiosPrivate();
       const navigateto = useNavigate();
       const [pwd, setPwd] = useState({
              password: '',
              newPassword: ''
       });
       const [confirmPwd, setConfirmPwd] = useState('');
       const [passwordError, setPasswordError] = useState(false);
       const [confirmPwdError, setConfirmPwdError] = useState(false);

       const onPasswordHandler = (e) => {
              e.preventDefault();

              const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!#$%^*?&])[A-Za-z\d@!#$%^*?&]{8,}$/

              if (!e.target.value || !passwordRegex.test(e.target.value)) setPasswordError(true);
              else setPasswordError(false)

              setPwd({ ...pwd, newPassword: e.target.value })
       }

       const onConfirmPwdHandler = (e) => {
              e.preventDefault();

              if (e.target.value === pwd.newPassword) setConfirmPwdError(false);
              else setConfirmPwdError(true);
              
              setConfirmPwd(e.target.value)
       }

       const handlePassword = async (e) => {
              e.preventDefault();

              if ( passwordError) return (alert("비밀번호는 영문자+숫자+특수문자 조합으로 8자리 이상이어야 합니다."))
        
              if ( pwd.newPassword !== confirmPwd)  
                     return (alert("비밀번호와 비밀번호 확인은 같아야 합니다.")) 

              try {
                     const response = await axiosPrivate.post('/user/my/password/change', pwd,
                            {
                                withCredentials: true
                            });
                     response.data && alert(response.data.message);
                     response.data && response.data.isSuccess && navigateto("/login");
              } catch (err) {
                     alert(err.message);
              }
       };

       return (
              <div className="vertical-flex-container-1">
                     <div className="position-center-box-1-1">
                            <h2>비밀번호 변경</h2>
                     </div>
                     <div className="position-center-box-1-1">
                            <div style={{marginBottom:"7px", marginTop:"25px"}}>
                                   <label htmlFor="password">현재 비밀번호:</label> 
                            </div>
                            <div>
                                   <input
                                          id="password"
                                          style={{width:"100%", height:"37px", border: "1px solid lightgray"}}
                                          type="password"
                                          // placeholder="비밀번호를 입력하세요"
                                          value={pwd.password}
                                          onChange={(e) => setPwd({ ...pwd, password: e.target.value })}
                                          required 
                                   />
                            </div>
                     </div>
                     <div className="position-center-box-1-1">
                            <div style={{marginBottom:"7px"}}>
                                   <label htmlFor="newPassword">새 비밀번호:</label>
                            </div>
                            <div>
                                   <input 
                                          id="newPassword"
                                          type="password" 
                                          style={{width:"100%", height:"37px", border: "1px solid lightgray"}} 
                                          value={pwd.newPassword} 
                                          onChange={onPasswordHandler} 
                                          required 
                                   />
                            </div>
                            <div style={{marginTop:"7px"}}>
                                   {!pwd.newPassword
                                          ? <span style={{fontSize:"0.8rem", color:"black"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
                                          : passwordError 
                                                 ? <span style={{fontSize:"0.8rem", color:"red"}}>영문대문자, 영문소문자, 숫자, 특수문자를 모두 사용해 8자리 이상으로 비밀번호를 만들어주세요!</span>
                                                 : <span style={{fontSize:"0.8rem", color:"blue"}}>사용가능한 비밀번호입니다.</span>
                                   }
                            </div>
                     </div>
                     <div className="position-center-box-1-1">
                            <div style={{marginBottom:"7px"}}>
                                   <label htmlFor="confirmPassword">새 비밀번호 확인:</label>
                            </div>
                            <div>
                                   <input 
                                          id="confirmPassword"
                                          type="password" 
                                          style={{width:"100%", height:"37px", border: "1px solid lightgray"}} 
                                          value={confirmPwd} 
                                          onChange={onConfirmPwdHandler} 
                                          required 
                                   />
                            </div>
                            <div style={{marginTop:"7px"}}>
                                   {!confirmPwd
                                          ? <span style={{fontSize:"0.8rem", color:"black"}}>비밀번호를 한 번 더 입력하세요.</span>
                                          : confirmPwdError
                                                 ? <span style={{fontSize:"0.8rem", color:"red"}}>비밀번호가 일치하지 않습니다.</span>
                                                 : <span style={{fontSize:"0.8rem", color:"blue"}}>비밀번호가 일치합니다.</span>
                                   }
                            </div>
                     </div>
                     <div className="position-center-box-1-1">
                            <div className="horizontal-flex-container">
                                   <button className="button" onClick={handlePassword}>
                                          비밀번호 변경
                                   </button>
                            </div>
                     </div>
              </div>
       );
}

export default ChangePassword;