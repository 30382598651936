import { useNavigate } from "react-router-dom";
import useLogout from '../hooks/useLogout';

const PaymentComplete = () => {
       const navigateto = useNavigate();
       const logout = useLogout();

       return(
              <div style={{ padding: "100px" }}>
                     <h3>"결제가 완료되었습니다. 로그아웃 후 다시 로그인하세요.</h3>
                     <button 
                            className="button"
                            onClick={() => logout()}
                     >
                            로그아웃
                     </button>
                     <button 
                            className="button"
                            onClick={() => navigateto('/login')}
                     >
                            로그인
                     </button>
              </div>
       )
}

export default PaymentComplete;