import React, { useState, useEffect, useRef, useCallback } from "react";
// import "./home.css"
import { Link } from "react-router-dom";
import useGetTodayProblemList from "../hooks/useGetTodayProblemList";
import Footer from "../components/Footer";

const TodayProblemList = () => {
    const [pageNumber, setPageNumber] = useState(1)

    const { loading, error, hasMore, todayProblemList } = useGetTodayProblemList(pageNumber)

    const [randomString1, setRandomString1] = useState();
    const [randomString2, setRandomString2] = useState();
    const [randomString3, setRandomString3] = useState();
    const [randomString4, setRandomString4] = useState();
    const [randomString5, setRandomString5] = useState();

    const observer = useRef()

    const lastProblemElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    useEffect(() => {
        const randomAlphabetString = () => {
            const letters = '1234567890';
            let randomString1l = '';
            let randomString2l = '';
            let randomString3l = '';
            let randomString4l = '';
            let randomString5l = '';
            for (let i = 0; i < 3; i++) {
                randomString1l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString1(randomString1l); 
            for (let i = 0; i < 8; i++) {
                randomString2l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString2(randomString2l);
            for (let i = 0; i < 5; i++) {
                randomString3l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString3(randomString3l);
            for (let i = 0; i < 7; i++) {
                randomString4l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString4(randomString4l);
            for (let i = 0; i < 6; i++) {
                randomString5l += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString5(randomString5l);
          };
          randomAlphabetString();
    },[])

    return(
        <div className="App-full-container">
            <div className="position-center-box-2-1">
                <div className="title">오늘의 문제</div>
            </div>
            <div className="position-center-box-2-1">
                {/* <div className="containerList">
                    <div className="containerListItem"> */}
                        {todayProblemList.map((prob, index) => {
                            if(todayProblemList.length === index+1) {
                                return (
                                    <div ref={lastProblemElementRef} key={prob.id}>
                                        <Link 
                                            to={`/todayproblem/${randomString1}${prob.id.toString().padStart(10,"0").slice(8,10)}${randomString2}${prob.id.toString().padStart(10,"0").slice(7,8)}${randomString3}${prob.id.toString().padStart(10,"0").slice(6,7)}${randomString4}${prob.id.toString().padStart(10,"0").slice(4,6)}${randomString5}${prob.id.toString().padStart(10,"0").slice(0,4)}`}
                                            state={{
                                                "todayProblemList": todayProblemList,
                                                "todayProblem": prob
                                            }}
                                        >
                                            {prob.todayProblemTitle}
                                        </Link> <br/>
                                        {prob.todayProblemDescription}
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={prob.id} style={{marginBottom:"1rem"}}>
                                        <Link 
                                            to={`/todayproblem/${randomString1}${prob.id.toString().padStart(10,"0").slice(8,10)}${randomString2}${prob.id.toString().padStart(10,"0").slice(7,8)}${randomString3}${prob.id.toString().padStart(10,"0").slice(6,7)}${randomString4}${prob.id.toString().padStart(10,"0").slice(4,6)}${randomString5}${prob.id.toString().padStart(10,"0").slice(0,4)}`}
                                            state={{
                                                "todayProblemList": todayProblemList,
                                                "todayProblem": prob
                                            }}
                                        >
                                            {prob.todayProblemTitle}
                                        </Link> <br/>
                                        {prob.todayProblemDescription}
                                    </div>
                                )
                            }
                        })}
                        <div>{loading && 'Loading...'}</div>
                        <div>{error && 'Error'}</div>
                    {/* </div>
                </div> */}
            </div>
            <div style={{width: "100%"}}>
                <Footer/>
            </div>
        </div>
    );
}

export default TodayProblemList;