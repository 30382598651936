import { React, useState, useEffect } from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axiosapi from '../api/axiosapi';
// import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
// import ViewMyAnswer from '../components/ViewMyAnswer';
import ProblemStatDescription from '../components/ProblemStatDescription';
import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';

const ImproveMathAbility = ()=> {
    const navigateto=useNavigate();
    const {cateProbID}=useParams();
    const {auth} = useAuth();
    const {categoryCode, problemCode, improveAbilityTitle, improveAbilityList, improveAbilityType} = useLocation().state;
    const location = useLocation();
    const {problem, errP} = GetProblemInfo({"problemID": problemCode});
    // const {isMyAnswer, latestAnswer, myAnswerList, errMyAns} = ViewMyAnswer({"problemCode": problemCode});
    const {useStat} = UserSolvingStat({"problemCode": problemCode});
    const [isFirst, setIsFirst] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [prevProblem, setPrevProblem] = useState('');
    const [nextProblem, setNextProblem] = useState('');
    // const [prevProblemId, setPrevProblemId] = useState('');
    // const [nextProblemId, setNextProblemId] = useState('');
    const [prevUrlAdd, setPrevUrlAdd] = useState();
    const [nextUrlAdd, setNextUrlAdd] = useState();
    const [categoryProblems, setCategoryProblems]=useState([]);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    // const [error2, setError2]=useState();
    const [errFD, setErrFD] = useState(false);
    const [problemOrder, setProblemOrder] = useState();
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    // const [problemIdFetched, setProblemIdFetched] = useState();
    // const [problemCodeFetched, setProblemCodeFetched] = useState();

    useEffect(()=> {
        const fetchCategoryProblemList = async () => {
            try {
                const response = await axiosapi.get('/categoryproblem/category/'+categoryCode);
                setCategoryProblems(response.data);
                setCount(count+1);
            } catch (e) {
                setErrFD(true);
                // setError2(e);
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            }
        }
        fetchCategoryProblemList()
    },[categoryCode]);

    useEffect(() => {
        const categorySetting = () => {
            categoryProblems.map(cate => {
                if(cate.problemCode === problemCode) {
                    setProblemOrder(cate.problemOrder);
                    setCount2(count2+1);
                }
            })
        }
        categorySetting();
    },[count, cateProbID])

    useEffect(() => {
        const getMoveToProblem = () => {
            if (problemOrder === 1) {
                setIsFirst(true);
                setPrevProblem(problemCode);
                // setPrevProblemId(problemIdFetched);
                setPrevUrlAdd(cateProbID);
            } else {
                setIsFirst(false);
                categoryProblems.map(cate => {
                    if(cate.problemOrder === problemOrder-1) {
                        setPrevProblem(cate.problemCode);
                        // setPrevProblemId(cate.id);
                        setPrevUrlAdd(cate.urlAdd);
                    }
                })
            }
            if (problemOrder === categoryProblems.length) {
                setIsLast(true);
                setNextProblem(problemCode);
                // setNextProblemId(problemIdFetched);
                setNextUrlAdd(cateProbID);
            } else {
                setIsLast(false);
                categoryProblems.map(cate => {
                    if(cate.problemOrder === problemOrder+1) {
                        setNextProblem(cate.problemCode);
                        // setNextProblemId(cate.id);
                        setNextUrlAdd(cate.urlAdd)
                    }
                })
            }
        }
        getMoveToProblem();
    },[count2, cateProbID])

    // useEffect(() => {
    //     const revivalData = () => {
    //         const letters = '1234567890';
    //         let randomString1l = '';
    //         let randomString2l = '';
    //         let randomString3l = '';
    //         let randomString4l = '';
    //         let randomString5l = '';
    //         for (let i = 0; i < 3; i++) {
    //             randomString1l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString1(randomString1l); 
    //         for (let i = 0; i < 8; i++) {
    //             randomString2l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString2(randomString2l);
    //         for (let i = 0; i < 5; i++) {
    //             randomString3l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString3(randomString3l);
    //         for (let i = 0; i < 7; i++) {
    //             randomString4l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString4(randomString4l);
    //         for (let i = 0; i < 6; i++) {
    //             randomString5l += letters[Math.floor(Math.random() * letters.length)];
    //         }
    //         setRandomString5(randomString5l);
    //         let substring = cateProbID.slice(35,41)+cateProbID.slice(27,29)+cateProbID.slice(19,20)+cateProbID.slice(13,14)+cateProbID.slice(3,5);
    //         let subStringNum = Number(substring);
    //         setProblemCodeFetched(substring);
    //         setProblemIdFetched(subStringNum);
    //       };
    //       revivalData();
    // },[cateProbID])

    if (errP || errFD) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="problem" key={problem.problemCode}>
            <div className="content-fit">
                <h5 style={{fontWeight:"bolder", color:"orange" ,marginTop:"22px", marginBottom:"15px"}}>{improveAbilityTitle}</h5>
                <ProblemStatDescription 
                    topCategoryTitle="작도와 함께 두뇌 활성화하기"
                    problemTitle={problem.problemTitle} 
                    problemDescription={problem.problemDescription}
                    problemId={problem.problemCode}
                    useStat={useStat}
                    problemType={problem.problemType}
                />
                {problem && <GeoGebraP
                    title={problem.problemTitle}
                    // description={problem.description}
                    idIn={problem.problemCode}
                    customToolBarIn={problem.customToolBar}
                    allowStyleBarIn={problem.allowStyleBar}
                    ggbBase64In={problem.problemContent}
                    problemType={problem.problemType}
                    showToolBarHelpIn={problem.showToolBarHelp}
                    enableLabelDragIn={problem.enableLabelDrag}
                    enableShiftDragZoomIn={problem.enableShiftDragZoom}
                />}
                <div className='horizontal-flex-container'>
                    {isFirst 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/improvemathability/${categoryCode}${prevUrlAdd}`, {state:{
                                "categoryCode": categoryCode,
                                "problemCode": prevProblem,
                                "improveAbilityTitle": improveAbilityTitle,
                                "improveAbilityList": improveAbilityList,
                                "improveAbilityType": improveAbilityType
                            }})}
                        >
                            이전 문제
                        </button>
                    } 
                    {isLast 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/improvemathability/${categoryCode}${nextUrlAdd}`, {state:{
                                "categoryCode": categoryCode,
                                "problemCode": nextProblem,
                                "improveAbilityTitle": improveAbilityTitle,
                                "improveAbilityList": improveAbilityList,
                                "improveAbilityType": improveAbilityType
                            }})}
                        >
                            다음 문제
                        </button>
                    }
                    {problem.problemFileType === 1 && (problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 4 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701 || problem.problemType === 10 || problem.problemType === 11 || problem.problemType === 12) 
                        ? <button 
                                className='button'
                                onClick={
                                    () => auth?.accessToken
                                        ? navigateto(`/improvemathability/my/s/list`, {state:{
                                                "categoryCode": categoryCode,
                                                "problemCode": problemCode,
                                                "improveAbilityTitle": improveAbilityTitle,
                                                "problem": problem,
                                                "useStat": useStat,
                                                "isFirst": isFirst,
                                                "isLast": isLast,
                                                "prevProblem": prevProblem,
                                                // "prevProblemId": prevProblemId,
                                                "prevUrlAdd": prevUrlAdd,
                                                "nextProblem": nextProblem,
                                                // "nextProblemId": nextProblemId,
                                                "nextUrlAdd": nextUrlAdd,
                                                "cateProbID": cateProbID,
                                                "improveAbilityList": improveAbilityList,
                                                "improveAbilityType": improveAbilityType
                                            }})
                                        : navigateto("/login", {state:{"from": location}}, {replace: true})
                                }
                            >
                                내 풀이보기
                            </button>
                        : null
                    }
                </div>
            </div>
            
        </div>
    );
};

export default ImproveMathAbility;
