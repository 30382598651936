import GeometricColoring from "./pages/GeometricColoring";
import GeometricColoringMy from "./pages/GeometricColoringMy";
// import GeometricColoringMyOutputList from "./pages/GeometricColoringMyOutputList";
import ImproveMathAbilityMy from "./pages/improveMathAbilityMy";
import ImproveMathAbilityMySolAnimation from "./pages/ImproveMathAbilityMySolAnimation";
import ImproveMathAbilityMyDesign from "./pages/ImproveMathAbilityMyDesign";
import MathSubjectMy from "./pages/MathSubjectMy";
import MathSubjectMySolAnimation from "./pages/MathSubjectMySolAnimation";
import TodayProblemMy from "./pages/TodayProblemMy";
import TodayProblemMyDesign from "./pages/TodayProblemMyDesign";
import TodayProblemMySolAnimation from "./pages/TodayProblemMySolAnimation";
// import GeometricColoringMyOutput from "./pages/GeometricColoringMyOutput";
import SearchStudent from "./pages/SearchStudent";
import ViewRegisteredStudentList from "./pages/ViewRegisteredStudentList";
import StudentConfirm from "./pages/StudentConfirm";
import StudentAnswerList from "./pages/StudentAnswerList";
import StudentAnswer from "./pages/StudentAnswer";
import StudentAnswerAnimation from "./pages/StudentAnswerAnimation";
import TodayProblemMyS from "./pages/TodayProblemMyS";
import TodayProblemModifyMyProblemDesign from "./pages/TodayProblemModifyMyProblemDesign";
import TodayProblemModifyMyProblemDesignList from "./pages/TodayProblemModifyMyProblemDesignList";
import MathSubjectMyS from "./pages/MathSubjectMyS";
import ExerciseCalculation from "./pages/ExerciseCalculation";
import TeacherPage from "./pages/TeacherPage";
import StudentPage from "./pages/StudentPage";
import ImproveMathAbilityMyS from "./pages/ImproveMathAbilityMyS";
import MathSubjectSample from './pages/MathSubjectSample';
import MathSubject from './pages/MathSubject';
import ExerciseCalculationSample from './pages/ExerciseCalculationSample';
import GeometricColoringSample from "./pages/GeometricColoringSample";
import PaymentFirst from "./pages/PaymentFirst";
import PaymentSecond from "./pages/PaymentSecond";
import PaymentComplete from "./pages/PaymentComplete";
import StudentMyInfo from "./pages/StudentMyInfo";
import TeacherMyInfo from "./pages/TeacherMyInfo";
import StudentUpdateMyInfo from "./pages/StudentUpdateMyInfo";
import TeacherUpdateMyInfo from "./pages/TeacherUpdateMyInfo";
import TeacherPurchaseHistory from "./pages/TeacherPurchaseHistory";
import StudentPurchaseHistory from "./pages/StudentPurchaseHistory";
import StudentFreeClass from "./pages/StudentFreeClass";
import StudentMyAnswerList from "./pages/StudentMyAnswerList";
import StudentMyAnswer from "./pages/StudentMyAnswer";
import TeacherMyAnswerList from "./pages/TeacherMyAnswerList";
import TeacherMyAnswer from "./pages/TeacherMyAnswer";
import StudentMyAnswerAnimation from "./pages/StudentMyAnswerAnimation";
import TeacherMyAnswerAnimation from "./pages/TeacherMyAnswerAnimation";
import TeacherChangePassword from "./pages/TeacherChangePassword";
import StudentChangePassword from "./pages/StudentChangePassword";
// import PaymentInicisReturn from "./pages/PaymentInicisReturn";
// import PaymentInicisClose from "./pages/PaymentInicisClose";
// import GeometricColoringContinue from "./pages/GeometricColoringContinue";

export default [
    {
        path:'/todayproblem/my/:proIDMy',
        element: TodayProblemMy,
        required: true
    },
    {
        path:'/todayproblem/my/s/list',
        element: TodayProblemMyS,
        required: true
    },
    {
        path:'/todayproblem/my/animation/:proIDMy',
        element: TodayProblemMySolAnimation,
        required: true
    },
    {
        path:'/todayproblem/my/design/:proIDMy',
        element: TodayProblemMyDesign,
        required: true
    },
    {
        path:'/todayproblem/my/design/problem/list',
        element: TodayProblemModifyMyProblemDesignList,
        required: true
    },
    {
        path:'/todayproblem/my/design/modify/problem/:canvasID',
        element: TodayProblemModifyMyProblemDesign,
        required: true
    },
    {
        path:'/improvemathability/my/:cateProbIDMy',
        element: ImproveMathAbilityMy,
        required: false
    },
    {
        path:'/improvemathability/my/s/list',
        element: ImproveMathAbilityMyS,
        required: true
    },
    {
        path:'/improvemathability/my/animation/:cateProbIDMy',
        element: ImproveMathAbilityMySolAnimation,
        required: true
    },
    {
        path:'/improvemathability/my/design/:cateProbIDMy',
        element: ImproveMathAbilityMyDesign,
        required: true
    },
    {
        path:'/mathsubject/:firstProbID',
        element: MathSubject,
        required: true
    },
    {
        path:'/mathsubject/my/s/list',
        element: MathSubjectMyS,
        required: false
    },
    {
        path:'/mathsubject/my/:firstProbIDMy',
        element: MathSubjectMy,
        required: false
    },
    {
        path:'/mathsubject/my/animation/:firstProbIDMy',
        element: MathSubjectMySolAnimation,
        required: true
    },
    {
        path:'/geometriccoloring/:geoID',
        element: GeometricColoring,
        required: true
    },
    {
        path:'/geometriccoloring/sample/:geoIDSample',
        element: GeometricColoringSample,
        required: true
    },
    // {
    //     path:'/geometriccoloring/continue/:geoContinueID',
    //     element: GeometricColoringContinue,
    //     required: true
    // },
    {
        path:'/geometriccoloring/my/:geoIDMy',
        element: GeometricColoringMy,
        required: true
    },
    // {
    //     path:'/geometriccoloring/my/output/:coloringMyOutput',
    //     element: GeometricColoringMyOutput,
    //     required: true
    // },
    // {
    //     path:'/geometriccoloring/my/output/list/:coloringMyOutputList',
    //     element: GeometricColoringMyOutputList,
    //     required: true
    // },
    {
        path:'/calculationexercise/',
        element: ExerciseCalculation,
        required: true
    },
    {
        path:'/calculationexercise/sample/',
        element: ExerciseCalculationSample,
        required: true
    },
    {
        path:'/teacher/student/searchstudent',
        element: SearchStudent,
        required: true
    },
    {
        path:'/teacher/student/viewregisteredstudentlist',
        element: ViewRegisteredStudentList,
        required: true
    },
    {
        path:'/teacher/student/studentconfirm',
        element: StudentConfirm,
        required: true
    },
    {
        path:'/teacher/student/studentanswerlist',
        element: StudentAnswerList,
        required: true
    },
    {
        path:'/teacher/student/studentanswer/:studentAns',
        element: StudentAnswer,
        required: true
    },
    {
        path:'/teacher/student/studentansweranimation/:studentAns',
        element: StudentAnswerAnimation,
        required: true
    },
    {
        path:'/teacher/work/',
        element: TeacherPage,
        required: true
    },
    {
        path:'/teacher/myinfo/',
        element: TeacherMyInfo,
        required: true
    },
    {
        path:'/teacher/myinfo/update/',
        element: TeacherUpdateMyInfo,
        required: true
    },
    {
        path:'/teacher/myinfo/payhistory/',
        element: TeacherPurchaseHistory,
        required: true
    },
    {
        path:'/teacher/myinfo/answer/list/',
        element: TeacherMyAnswerList,
        required: true
    },
    {
        path:'/teacher/myinfo/changepassword/',
        element: TeacherChangePassword,
        required: true
    },
    {
        path:'/teacher/myinfo/answer/item/:teacherMyAnswerMark',
        element: TeacherMyAnswer,
        required: true
    },
    {
        path:'/teacher/myinfo/answer/item/animation/:teacherMyAnswerMarkAni',
        element: TeacherMyAnswerAnimation,
        required: true
    },
    {
        path:'/student/work/',
        element: StudentPage,
        required: true
    },
    {
        path:'/student/myinfo/',
        element: StudentMyInfo,
        required: true
    },
    {
        path:'/student/myinfo/update/',
        element: StudentUpdateMyInfo,
        required: true
    },
    {
        path:'/student/myinfo/changepassword/',
        element: StudentChangePassword,
        required: true
    },
    {
        path:'/student/myinfo/payhistory/',
        element: StudentPurchaseHistory,
        required: true
    },
    {
        path:'/student/myinfo/answer/list/',
        element: StudentMyAnswerList,
        required: true
    },
    {
        path:'/student/myinfo/answer/item/:studentMyAnswerMark',
        element: StudentMyAnswer,
        required: true
    },
    {
        path:'/student/myinfo/answer/item/animation/:studentMyAnswerMarkAni',
        element: StudentMyAnswerAnimation,
        required: true
    },
    {
        path:'/student/work/freeclass/',
        element: StudentFreeClass,
        required: true
    },
    {
        path:'/mathsubject/sample/:firstProbIDSample',
        element: MathSubjectSample,
        required: true
    },
    {
        path:'/payment/1',
        element: PaymentFirst,
        required: true
    },
    {
        path:'/payment/2',
        element: PaymentSecond,
        required: true
    },
    // {
    //     path:'/payment/inicisreturn',
    //     element: PaymentInicisReturn,
    //     required: true
    // },
    // {
    //     path:'/payment/inicisclose',
    //     element: PaymentInicisClose,
    //     required: true
    // },
    {
        path:'/payment/complete',
        element: PaymentComplete,
        required: true
    }
]