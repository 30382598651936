import React, { useState, useEffect } from "react";
// import "./mathsubject.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import axiosapi from "../api/axiosapi";
// import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ForTeacherMenu from "../components/ForTeacherMenu";
// import axiosPrivate from "../api/axiosPrivate";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const StudentAnswerList = () => {
    // const {auth} = useAuth();
    const {student} = useLocation().state;
    const [studentAnswers, setStudentAnswers] = useState([]);
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigateto=useNavigate();
	const location = useLocation();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchStudentAnswerList = async () => {
            try {
                const response = await axiosPrivate.get('/teacher/student/problemsolvinglist', {
                    signal: controller.signal,
                    params: {
                        studentID: student.studentID
                    }
                })
                isMounted && response.data && setStudentAnswers(response.data);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

        fetchStudentAnswerList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[student])


    return(
        <div className="App-full-row-container">
            <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                <ForTeacherMenu />
            </div>
            <div className="content-box-1">
                <h4 style={{ marginBottom:"10px" }}>{student.studentName}의 문제풀이 리스트</h4>
                <table style={{width:"100%", maxWidth:"900px"}}>
                    <thead style={{ backgroundColor: 'yellow', height:"37px" }}>
                        <tr>
                            <th style={{minWidth:"240px", width:"60%"}}>문제</th>
                            <th style={{minWidth:"100px", width:"25%"}}>풀이 시각</th>
                            <th style={{minWidth:"60px", width:"15%"}}>풀이 여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentAnswers.map((answer, index) => {
                            return (
                                <tr key={answer.id} style={{ height:"37px", backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey' }}>
                                    <td>
                                        <Link 
                                            to={`/teacher/student/studentanswer/${answer.answerMark}`}
                                            state={{
                                                "student": student,
                                                "studentAnswer": answer,
                                                "studentAnswers": studentAnswers
                                            }}
                                        >
                                            {answer.problemTitle && splitContent(answer.problemTitle)}
                                        </Link>
                                    </td>
                                    <td>{answer.solvingTime}</td>
                                    <td>{answer.isSuccess ? "풀이 성공" : "풀이 실패"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="boxListFooter-11">
                Footer
            </div>
        </div>
    );
}

export default StudentAnswerList;