import React, { useEffect, useState } from "react";
// import './signup.css';
// import '../App.css';
// import { useNavigate } from "react-router-dom";
import axiosapi from "../api/axiosapi";

const ViewPrivacyPolicy = () => {
    const [privacyPolicyO, setPrivacyPolicyO] = useState([]);
	const [err, setErr] = useState('');
    // const navigateto = useNavigate();

    useEffect(() => {
        const controller = new AbortController();

        const fetchPrivacyPolicy = async() => {
            try {
                const response = await axiosapi.get('/policy/list/policy001');
                const privacyPolicyO = response.data && response.data.content.split("|");
                setPrivacyPolicyO(privacyPolicyO);
            } catch (err) {
                setErr(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchPrivacyPolicy();

        return () => controller.abort();
    },[])

    return(
        <div className="App-full-container">
            <div className="position-center-box-2-1">
                <div style={{marginTop:"25px"}}>
                    <h2>개인정보 수집 및 이용 정책</h2>
                    <br></br>
                    <div>{privacyPolicyO && privacyPolicyO.map(item => (<div><p>{item}</p></div>))}</div>
                </div>
            </div>
        </div>
    )
}

export default ViewPrivacyPolicy;