import { React, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
// import useAuth from '../hooks/useAuth';
import Geogebra from 'react-geogebra';
import ViewMyColoringItem from '../components/ViewMyColoringItem';
// import axiosapi from '../api/axiosapi';
import useAxiosImagePrivate from '../hooks/useAxiosImagePrivate';

const GeometricColoringMy = ()=> {
    // const {auth}=useAuth();
    const navigateto=useNavigate();
    const {geoIDMy}=useParams();
    const {canvasCategoryCode, canvasCategoryTitle, myColoringList, canvasCode, prevCanvas, nextCanvas, isFirst, isLast, geoID, isSample} = useLocation().state;
    const {myColoringFetched, errAns} = ViewMyColoringItem({"answerMark": geoIDMy});
    const [selectedColoring, setSectedColoring] = useState();
    const [myColoring, setMyColoring] = useState();
    const [title, setTitle] = useState('');
    const location = useLocation();
    // const [myColoringListU, setMyColoringListU] = useState([]);
    const [flag, setFlag] = useState('');
    const [bcolor,setBcolor] = useState('');
    const [backColor, setBackColor] = useState('WHITE');
    const formData = new FormData();
    const axiosImagePrivate = useAxiosImagePrivate();
    const [flag2, setFlag2] = useState(false);

    useEffect(() => {
        if (errAns) {
            navigateto('/login', { state: { from: location }, replace: true });
        } else {
            setMyColoring(myColoringFetched);
            setFlag(geoIDMy);
        }
    },[myColoringFetched, errAns])

    const saveMyColoring = async () => {
        let isMounted = true;
        const controller = new AbortController();

        try {
            const response = await axiosImagePrivate.post('/coloring/my/history/add/', formData, {
                signal: controller.signal
            });
            isMounted && response.data && setFlag2(true);
            alert("이 그림을 저장했습니다.");
        } catch (err) {
            // console.log(err);
            setFlag2(false);
            if (err?.response?.status === 500) {
                alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
                navigateto('/login', { state: { from: location }, replace: true });
            } else {
                alert("그림을 저장하지 못했습니다. 잠시 후 다시 시도해 주세요.");
            }
        }

        // axiosImagePrivate.post('/coloring/my/history/add/', formData)
        // .then (response => {
        //     prevTime = new Date();
        //     alert("이 그림을 저장했습니다.");
        // }) 
        // .catch(err => {
        //     console.log(err);
        //     alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.");
        // })

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    let prevTime=new Date();

    const onClickHandler = () => {
        const app=window.ggbApplet;
        let date = new Date();
        let elapsedTime = date - prevTime;
        console.log('결과시간:', elapsedTime)
        if (elapsedTime > 30000) {
            formData.append('canvasCode', canvasCode);
            let viewDate = date.toLocaleString('en-GB');
            formData.append('coloringTime', viewDate);
            formData.append('coloringTitle', title);
            let answerMark = canvasCode.substring(0,9)+viewDate.substring(0,2)+viewDate.substring(3,5)+viewDate.substring(6,10)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
            formData.append('answerMark', answerMark);
            formData.append('backgroundColor', backColor);
            let myGGB = app.getBase64();
            formData.append('snapshot', myGGB);
            let image = app.getPNGBase64(1,true,72);
            formData.append('image_data', image);
            if (title === "") {
                alert("그림의 제목을 입력하세요.");
            } else {
                saveMyColoring();
                if (flag2) prevTime = new Date();
                // axiosapi.post('/coloring/my/history/add', formData, {
                //     headers: {
                //         'Authorization': `Bearer ${auth?.accessToken}`
                //     }
                // })
                //     .then (response => {
                //         prevTime = new Date();
                //         alert("이 그림을 저장했습니다.");
                //     })
                //     .catch(err => {
                //         alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.")
                //     })
            }
            // prevTime = new Date();
            // alert("이 그림을 저장했습니다.");
        } else alert("30초 이상 경과해야 그림을 저장할 수 있습니다.")
    }

    const onTitleHandler = (e) => {
        let tempTitle = e.target.value || myColoringFetched.coloringTitle;
        setTitle(tempTitle);
    }
    
    const onBackgroundColorHandler = () => {
        const app=window.ggbApplet;
        bcolor !== "" ? setBackColor(bcolor.toUpperCase()) : setBackColor('WHITE');
        app.evalCommand(`SetBackgroundColor(${backColor})`);
    }

    return(
        <div className="problem" key={geoIDMy}>
            <div className="content-fit">
                <div style={{marginTop:"25px"}}>
                    <select value={selectedColoring} style={{height:"36px"}} onChange={(e) => setSectedColoring(e.target.value)}>
                        <option value>그림을 그린 때를 선택하세요.</option>
                        {myColoringList.map(myAns => {
                            return <option value={myAns.answerMark} key={myAns.answerMark}>
                                {myAns.coloringTime.substring(6,10)}년 {myAns.coloringTime.substring(3,5)}월 {myAns.coloringTime.substring(0,2)}일 {myAns.coloringTime.substring(12,17)}
                            </option>
                        })}
                    </select>
                    <button 
                        className='button' 
                        style={{marginLeft:"10px"}}
                        onClick={() => navigateto(`/geometriccoloring/my/${selectedColoring}`, {state: {
                            "canvasCategoryCode": canvasCategoryCode,
                            "canvasCategoryTitle": canvasCategoryTitle,
                            "myColoringList": myColoringList,
                            "canvasCode": canvasCode,
                            "prevCanvas": prevCanvas,
                            "nextCanvas": nextCanvas,
                            "isFirst": isFirst,
                            "isLast": isLast,
                            "geoID": geoID,
                            "isSample": isSample
                        }})}
                    >
                        그림 보기
                    </button>
                </div>
                <div>
                    <input 
                        type="text" 
                        id="title"
                        style={{width:"90%", height:"38px", marginBottom:"9px"}}
                        placeholder={myColoring && myColoring.coloringTitle + "(그림 제목을 바꿀 수 있습니다.)"}
                        value={title} 
                        onChange={onTitleHandler} 
                        required 
                    />
                </div>

                <div>
                    <input 
                        type="text" 
                        id="color"
                        style={{width:"320px", height:"38px", marginBottom:"9px"}}
                        placeholder="SpringGreen처럼 영문으로 배경색을 입력"
                        onChange={(event) => setBcolor(event.target.value)} 
                    />
                    <button 
                        className='button'
                        style={{marginLeft:"9px", marginTop: "0px", marginRight:"9px"}}
                        onClick={onBackgroundColorHandler}
                    >
                        배경색 변경
                    </button>
                    (2번 클릭하면 변경됩니다. <a href="https://www.w3schools.com/tags/ref_colornames.asp" target='blank'>색 참조</a>)
                </div>

                <div className="problemmain" style={{marginTop:"15px"}}  key={flag}>
                    {myColoring ? <Geogebra
                        id="ggbApplet"
                        width={1100}
                        height={720}
                        // autoHeight={true}
                        // appletOnLoad={onLoad}
                        showMenuBar={false}
                        showAlgebraInput={false}
                        showToolBar={true}
                        customToolBar="0 | 62 | 40 | 41 | 42 | 6"
                        showToolBarHelp={false}
                        showResetIcon={false}
                        enableLabelDrags={true}
                        enableShiftDragZoom={false}
                        enableRightClick={false}
                        errorDialogsActive={false}
                        useBrowserForJS={false}
                        allowStyleBar={true}
                        preventFocus={false}
                        showZoomButtons={true}
                        capturingThreshold={3}
                        rounding="8"
                        // add code here to run when the applet starts
                        showFullscreenButton={true}
                        scale={1}
                        disableAutoScale={false}
                        allowUpscale={false}
                        clickToLoad={false}
                        appName="classic"
                        buttonRounding={0.7}
                        buttonShadows={false}
                        language="ko"
                        ggbBase64={`${myColoring.snapshot}`}
                    /> : "Loading"}
                </div>
                <div className='horizontal-flex-container'>
                    {isFirst 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => isSample 
                                ? navigateto(`/geometriccoloring/sample/${geoID}b`, {state:{
                                    "canvasCategoryCode": canvasCategoryCode,
                                    "canvasCategoryTitle": canvasCategoryTitle,
                                    "canvasCode": prevCanvas
                                }})
                                : navigateto(`/geometriccoloring/${geoID}b`, {state:{
                                    "canvasCategoryCode": canvasCategoryCode,
                                    "canvasCategoryTitle": canvasCategoryTitle,
                                    "canvasCode": prevCanvas
                                }})
                            }
                        >
                            이전 그림판
                        </button>
                    } 
                    {isLast 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => isSample 
                                ? navigateto(`/geometriccoloring/sample/${geoID}b`, {state:{
                                    "canvasCategoryCode": canvasCategoryCode,
                                    "canvasCategoryTitle": canvasCategoryTitle,
                                    "canvasCode": nextCanvas
                                }})
                                : navigateto(`/geometriccoloring/${geoID}b`, {state:{
                                    "canvasCategoryCode": canvasCategoryCode,
                                    "canvasCategoryTitle": canvasCategoryTitle,
                                    "canvasCode": nextCanvas
                                }})
                            }
                        >
                            다음 그림판
                        </button>
                    }
                    <button 
                        className='button'
                        onClick={() => isSample 
                            ? navigateto(`/geometriccoloring/sample/${geoID}b`, {state:{
                                "canvasCategoryCode": canvasCategoryCode,
                                "canvasCategoryTitle": canvasCategoryTitle,
                                "canvasCode": canvasCode
                            }})
                            : navigateto(`/geometriccoloring/${geoID}b`, {state:{
                                "canvasCategoryTitle": canvasCategoryTitle,
                                "canvasCategoryCode": canvasCategoryCode,
                                "canvasCode": canvasCode
                            }})
                        }
                    >
                        원래 그림판
                    </button>
                    <button 
                        className='button'
                        onClick={onClickHandler}
                    >
                        수정 그림 보관하기
                    </button>
                </div>
            </div>
            <br/>
            
        </div>
    );
};

export default GeometricColoringMy;
