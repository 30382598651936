import React, {useState, useEffect} from 'react';
import GeoGebraP from '../components/GeoGebraP';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../components/SideBar';
// import axiosapi from '../api/axiosapi';
// import './mathsubject.css';
// import '../App.css';
import GetProblemInfo from '../components/GetProblemInfo';
import ProblemStatDescription from '../components/ProblemStatDescription';
import useAuth from '../hooks/useAuth';
import UserSolvingStat from '../components/UserSolvingStat';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const MathSubjectSample = ()=> {
    const {firstProbIDSample}=useParams();
    const {subjectCode, problemCode, subjectTitle} = useLocation().state;
    const {auth} = useAuth();
    const location = useLocation();
    const [subject, setSubject] = useState('');
    const [count, setCount] = useState(0);
    // const [error2, setError2]=useState();
    const navigateto=useNavigate();
    const {problem, errP} = GetProblemInfo({"problemID": problemCode});
    const [problemCategory, setProblemCategory] = useState();
    const [images, setImages] = useState([]);
    const isSample = true;
    const {useStat} = UserSolvingStat({"problemCode": problemCode});
    const axiosPrivate = useAxiosPrivate();

    useEffect(()=> {
        let isMounted = true;
        const controller = new AbortController();

        const fetchSubjectList = async () => {
            try {
                const response = await axiosPrivate.get('/subjectproblem/sample/', {
                    params: {
                        subject_code: subjectCode
                    },
                    signal: controller.signal
                })
                isMounted && response.data && setSubject(response.data);
                isMounted && response.data && setCount(count+1);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // setError2(e)
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            }
        }

        fetchSubjectList()

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[subjectCode]);

    useEffect(() => {
        const problemCategorySetting = () => {
            subject && subject.categories.map(cate => {
                cate.problems.map(prob => {
                    if(prob.problemCode === problemCode) {
                        setProblemCategory(cate.categoryCode);
                    }
                })
            })
        }
        problemCategorySetting();
    },[count, problem])

    useEffect(() => {
        const imagesSetting = () => {
            setImages([]);
            problem.images && problem.images.map(image => {
                setImages(prev => [...prev, image]);
            })
        }

        imagesSetting();
    },[problem])

    if (errP) {
        return (
            <div className="problem">
                <p>"문제를 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="App-full-row-container" key={problem.problemCode}>
            <div className="left-menu-box-1">
                {problem && problemCategory && <SideBar 
                    topCategory={subjectCode}
                    problemCate={problemCategory} 
                    categories={subject.categories}
                    problemID={problem.problemCode}
                    topCategoryTitle={subject.subjectTitle}
                    problemId={problem.problemCode}
                    isSample={isSample}
                />}
            </div>
            <div className="content-box-1">
                <div className="problem">
                    <div style={{minWidth: "400px"}}></div>
                    <div className="content-fit">
                        <ProblemStatDescription 
                            topCategoryTitle={subjectTitle}
                            problemTitle={problem.problemTitle} 
                            problemDescription={problem.problemDescription}
                            problemId={problem.idIn}
                            useStat={useStat}
                            problemType={problem.problemType}
                        />
                        {problem && <GeoGebraP
                            title={problem.problemTitle}
                            idIn={problem.problemCode}
                            customToolBarIn={problem.customToolBar}
                            allowStyleBarIn={problem.allowStyleBar}
                            ggbBase64In={problem.problemContent}
                            problemType={problem.problemType}
                            showToolBarHelpIn={problem.showToolBarHelp}
                            enableLabelDragIn={problem.enableLabelDrag}
                            enableShiftDragZoomIn={problem.enableShiftDragZoom}
                        />}
                        <div className='horizontal-flex-container'>
                            {problem.problemFileType === 1 && (problem.problemType === 1 || problem.problemType === 2 || problem.problemType === 4 || problem.problemType === 6 || problem.problemType === 7 || problem.problemType === 701 || problem.problemType === 10 || problem.problemType === 11 || problem.problemType === 12)
                                ? <button 
                                        className='button'
                                        onClick={
                                            () => auth?.accessToken
                                                ? navigateto(`/mathsubject/my/s/list`, {state:{
                                                    "subjectCode": subjectCode,
                                                    "problemCode": problemCode,
                                                    "subjectTitle": subjectTitle,
                                                    "subject":subject,
                                                    "problem": problem,
                                                    "problemCategory": problemCategory,
                                                    "useStat": useStat,
                                                    "firstProbID": firstProbIDSample,
                                                    "isSample": isSample
                                                }})
                                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                                        }
                                    >
                                        내 풀이보기
                                    </button>
                                : null
                            }
                            {images.length > 0 && <button 
                                    className='button'
                                    onClick={() => navigateto(`/mathsubject/image/list/`, {state:{
                                        "subjectCode": subjectCode,
                                        "problemCode": problemCode,
                                        "subjectTitle": subjectTitle,
                                        "firstProbID": firstProbIDSample,
                                        "problemTitle": problem.problemTitle,
                                        "images": images,
                                        "isSample": isSample
                                    }})}
                                >
                                    그림보기
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default MathSubjectSample;