import React, { useEffect, useState } from "react";
// import './signup.css';
import { useNavigate } from "react-router-dom";
import axiosapi from "../api/axiosapi";

const FreeAgreement = () => {
    const [freeMembershipAgreementO, setFreeMembershipAgreementO] = useState([]);
    const [agreeFreeMembershipAgreement, setAgreeFreeMenbershipAgreement] = useState(false);
    const [privacyPolicyO, setPrivacyPolicyO] = useState([]);
    const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
    const [agreeAll, setAgreeAll] = useState(false);
    const [errPP, setErrPP] = useState('');
    const [errFA, setErrFA] = useState('');
    const navigateto = useNavigate();

    const toggleAgreeFreeMenbership = () => {
        setAgreeFreeMenbershipAgreement(prev => !prev)
    }

    const togglePrivacyPolicy = () => {
        setAgreePrivacyPolicy(prev => !prev)
    }

    const toggleAll = () => {
        setAgreeAll(prev => !prev)
    }

    useEffect(() => {
        const controller = new AbortController();

        const fetchPrivacyPolicy = async() => {
            try {
                const response = await axiosapi.get('/policy/list/policy001');
                const privacyPolicyO = response.data && response.data.content.split("|");
                setPrivacyPolicyO(privacyPolicyO);
            } catch (err) {
                setErrPP(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchPrivacyPolicy();

        return () => controller.abort();
    },[])

    useEffect(() => {
        const controller = new AbortController();

        const fetchFreeMembership = async() => {
            try {
                const response = await axiosapi.get('/policy/list/policy002')
                const freeMembershipAgreementO = response.data && response.data.content.split("|");
                setFreeMembershipAgreementO(freeMembershipAgreementO)
            } catch (err) {
                setErrFA(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }

        fetchFreeMembership();

        return () => controller.abort();
    },[])

    useEffect(() => {
        if (agreeAll) {
            setAgreeFreeMenbershipAgreement(true);
            setAgreePrivacyPolicy(true);
        } else {
            setAgreeFreeMenbershipAgreement(false);
            setAgreePrivacyPolicy(false);
        }
    },[agreeAll]);

    useEffect(() => {
        if(agreeFreeMembershipAgreement && agreePrivacyPolicy) setAgreeAll(true);
    },[agreeFreeMembershipAgreement, agreePrivacyPolicy]);

    return(
        <div className="App-full-container">
            <div className="position-center-box-2">
                <div>
                    <h5>무료회원 이용약관</h5>
                    <div style={{height: "300px", overflow: "scroll", border: "1px solid black"}}>{freeMembershipAgreementO && freeMembershipAgreementO.map(item => (<div><p>{item}</p></div>))}</div>
                    <input type="checkbox" id="freeMem" onChange={toggleAgreeFreeMenbership} checked={agreeFreeMembershipAgreement} />
                    <label htmlFor="freeMem">무료회원 이용약관에 동의</label>
                </div>
                <div style={{marginTop:"25px"}}>
                    <h5>개인정보 수집 및 이용 정책</h5>
                    <div style={{height: "300px", overflow: "scroll", border: "1px solid black"}}>{privacyPolicyO && privacyPolicyO.map(item => (<div><p>{item}</p></div>))}</div>
                    <input type="checkbox" id="privacy" onChange={togglePrivacyPolicy} checked={agreePrivacyPolicy} />
                    <label htmlFor="privacy">개인정보 수집 및 이용 정책에 동의</label>
                </div>
                <div style={{marginTop:"25px"}}>
                    <input type="checkbox" id="agreeall" onChange={toggleAll} checked={agreeAll} />
                    <label htmlFor="agreeall">모두 동의</label>
                </div>
                <div className="horizontal-flex-container">
                    <button 
                        className="button"
                        onClick={() => agreeFreeMembershipAgreement && agreePrivacyPolicy 
                            ? navigateto('/signup', {state: {agreeFM: agreeFreeMembershipAgreement, agreePP: agreePrivacyPolicy}}) 
                            : alert("무료회원 이용약관과 개인 정보 수집 및 이용 정책에 동의해야 회원가입을 할 수 있습니다.")
                        }
                    >
                        확인
                    </button>
                    <button 
                        className="button"
                        onClick={() => navigateto(-1)}
                    >
                        취소
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FreeAgreement;