import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import './login.css'
// import '../App.css'

const ForgotIdSecondMobile = () => {
    const location = useLocation();
    const navigateto = useNavigate();
    const [hiddenUsername, setHiddenUsername] = useState('');
    
    const HideCharater = () => {
        let hiddenCharacter="";
        for (let i = 1; i < location.state.usernameLength+1; i++) {
            if(i <= location.state.usernameLength/2) {
                hiddenCharacter=hiddenCharacter+location.state.username.substring(i-1,i)
            } else {
                hiddenCharacter=hiddenCharacter+"*"
            }
        }
        setHiddenUsername(hiddenCharacter);
        return(
            <div>
                {location.state.name}님의 아이디: {hiddenUsername}
            </div>
        )
    }

    return(
        <div className="App-full-screen-container">
            <div className="position-center-box-1-1">
                <div className="forgotIdBox-1">
                    {location.state.name}/{location.state.registeredMobile} 님으로 찾은 아이디입니다.<br/>
                    개인정보보호를 위해 {location.state.usernameLength}자리의 아이디 중 일부는 *로 표기됩니다.
                </div>
                <div className="forgotIdBox-2">
                    <HideCharater />
                </div>
                <div className="forgotIdBox-3">
                    <button 
                        className="button" 
                        onClick={() => navigateto('/login')}
                    >
                        로그인
                    </button>
                    <button 
                        className="button"
                        onClick={() => navigateto(`/forgotid/third`, {state:{
                            bridgeway: 2
                        }})}
                    >
                        다음 단계
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ForgotIdSecondMobile;