import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
import GetCanvasInfo from '../components/GetCanvasInfo';
import ViewMyColoring from '../components/ViewMyColoring';
import useAuth from '../hooks/useAuth';
import Geogebra from 'react-geogebra';
import axiosapi from '../api/axiosapi';
import axiosimage from '../api/axiosimage';
import useAxiosImagePrivate from '../hooks/useAxiosImagePrivate';

const GeometricColoring = () => {
    const navigateto=useNavigate();
    const {geoID}=useParams();
    const {auth} = useAuth();
    const {canvasCategoryCode, canvasCategoryTitle, canvasCode} = useLocation().state;
    const location = useLocation();
    const {canvas, errP} = GetCanvasInfo({"canvasCode": canvasCode});
    const {isMyColoring, myColoringListFetched, latestColoring, errMyAns} = ViewMyColoring({"canvasCode": canvasCode})
    const [categoryCanvas, setCategoryCanvas] = useState([]);
    const [canvasOrder, setCanvasOrder] = useState();
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    // const [count3, setCount3] = useState(0);
    const [isFirst, setIsFirst] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [prevCanvas, setPrevCanvas] = useState('');
    const [nextCanvas, setNextCanvas] = useState('');
    // const [prevCanvasId, setPrevCanvasId] = useState('');
    // const [nextCanvasId, setNextCanvasId] = useState('');
    // const [errTMA, setErrTMA] = useState();
    const [errFD, setErrFD] = useState(false);
    const [myColoringList, setMyColoringList] = useState([]);
    const [title, setTitle] = useState('');
    const [bcolor,setBcolor] = useState('');
    const [backColor, setBackColor] = useState('WHITE');
    const formData = new FormData();
    const isSample = false;
    const axiosImagePrivate = useAxiosImagePrivate();
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        const controller = new AbortController();

        const fetchColoringList = async () => {
            try {
                const response = await axiosapi.get('/canvas/bycategory/'+canvasCategoryCode);
                response.data && setCategoryCanvas(response.data);
                setCount(count+1);
            } catch (err) {
                setErrFD(true);
                // setErrTMA(err.message);
                // if (err.response) {
                //     console.log(err.response.data);
                //     console.log(err.response.status);
                //     console.log(err.response.headers);
                // } else {
                //     console.log(`Error: ${err.message}`);
                // }
            }
        }
        fetchColoringList();

        return () => controller.abort();
    },[geoID])

    useEffect(() => {
        const categorySetting = () => {
            categoryCanvas.map(cate => {
                if(cate.canvasCode === canvasCode) {
                    setCanvasOrder(cate.canvasOrder);
                    setCount2(count2+1);
                }
            })
        }
        categorySetting();
    },[count, canvas])

    useEffect(() => {
        const getMoveToProblem = () => {
            if (canvasOrder === 1) {
                setIsFirst(true);
                setPrevCanvas(canvasCode);
                // setPrevCanvasId(canvas.id);
            } else {
                setIsFirst(false);
                categoryCanvas.map(cate => {
                    if(cate.canvasOrder === canvasOrder-1) {
                        setPrevCanvas(cate.canvasCode);
                        // setPrevCanvasId(cate.id);
                    }
                })
            }
            if (canvasOrder === categoryCanvas.length) {
                setIsLast(true);
                setNextCanvas(canvasCode);
                // setNextCanvasId(canvas.id);
            } else {
                setIsLast(false);
                categoryCanvas.map(cate => {
                    if(cate.canvasOrder === canvasOrder+1) {
                        setNextCanvas(cate.canvasCode);
                        // setNextCanvasId(cate.id);
                    }
                })
            }
        }
        getMoveToProblem();
    },[count2, categoryCanvas])

    useEffect(() => {
        setMyColoringList(myColoringListFetched);
    },[myColoringListFetched])

    const saveMyColoring = async () => {
        let isMounted = true;
        const controller = new AbortController();

        try {
            const response = await axiosImagePrivate.post('/coloring/my/history/add/', formData, {
                signal: controller.signal
            });
            isMounted && response.data && setFlag(true);
            alert("이 그림을 저장했습니다.");
        } catch (err) {
            // console.log(err);
            setFlag(false);
            if (err?.response?.status === 500) {
                alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
                navigateto('/login', { state: { from: location }, replace: true });
            } else {
                alert("그림을 저장하지 못했습니다. 잠시 후 다시 시도해 주세요.");
            }
        }

        // axiosImagePrivate.post('/coloring/my/history/add/', formData)
        // .then (response => {
        //     prevTime = new Date();
        //     alert("이 그림을 저장했습니다.");
        // }) 
        // .catch(err => {
        //     console.log(err);
        //     alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.");
        // })

        return () => {
            isMounted = false;
            controller.abort();
        }
    }
    

    const onTitleHandler = (e) => {
        setTitle(e.target.value)
    }
    
    const onBackgroundColorHandler = () => {
        const app=window.ggbApplet;
        bcolor !== "" ? setBackColor(bcolor.toUpperCase()) : setBackColor('WHITE');
        app.evalCommand(`SetBackgroundColor(${backColor})`);
    }

    let prevTime=new Date();

    const onClickHandler = () => {
        const app=window.ggbApplet;
        let date = new Date();
        let elapsedTime = date - prevTime;
        let userId = auth?.accessToken || "visit";
        if (elapsedTime > 30000  && userId !== "visit") {
            formData.append('canvasCode', canvasCode);
            let viewDate = date.toLocaleString('en-GB');
            formData.append('coloringTime', viewDate);
            formData.append('coloringTitle', title);
            let answerMark = canvas.canvasCode.substring(0,9)+viewDate.substring(0,2)+viewDate.substring(3,5)+viewDate.substring(6,10)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
            formData.append('answerMark', answerMark);
            let myGGB = app.getBase64();
            formData.append('snapshot', myGGB);
            formData.append('backgroundColor', backColor);
            let image = app.getPNGBase64(1,true,72);
            formData.append('image_data', image);
            console.log("Form 데이터 : ", formData);
            if (title === "") {
                alert("그림의 제목을 입력하세요.");
            } else {
                saveMyColoring();
                if (flag) prevTime = new Date();
                // let isMounted = true;
                // const controller = new AbortController();

                // axiosImagePrivate.post('/coloring/my/history/add/', formData, {
                //     signal: controller.signal
                // })
                // .then (response => {
                //     prevTime = new Date();
                //     alert("이 그림을 저장했습니다.");
                // })
                // .catch(err => {
                //     alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.")
                // })

                // isMounted = false;
                // controller.abort();
            }
            // prevTime = new Date();
            // alert("이 그림을 저장했습니다.");
        } else if (elapsedTime > 30000) {
            formData.append('canvasCode', canvasCode);
            formData.append('userId', userId);
            let viewDate = date.toLocaleString('en-GB');
            formData.append('coloringTime', viewDate);
            formData.append('coloringTitle', title);
            formData.append('backgroundColor', backColor);
            let image = app.getPNGBase64(1,true,72);
            formData.append('image_data', image);
            if (title === "") {
                alert("그림의 제목을 입력하세요.");
            } else {
                axiosimage.post('/coloring/my/visit/history/add/', formData)
                    .then (response => {
                        prevTime = new Date();
                        alert("이 그림을 저장했습니다.");
                    })
                    .catch(err => {
                        alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.")
                    })
            }
            // prevTime = new Date();
            // alert("이 그림을 저장했습니다.");
        } else alert("30초 이상 경과해야 그림을 저장할 수 있습니다.")
    }

    if (auth?.userRole2 !== "premium") {
        return (
            <div className="problem">
                <h2>"유료회원이 이용하는 서비스입니다."</h2>
                <br></br>
                <p>티프매쓰는 온라인에서 가장 많은 작도 문제를 제공하는 웹사이트입니다.</p>
                <p>작도툴을 사용하는 방법을 익힌 다음 기본적인 주제부터 고급그런 주제에 이르는 풍성한 작도 문제를 통해 두뇌 활동을 촉진하세요.</p>
                <p>체계적인 사고력을 기반으로 상상력과 직관력을 최고 수준으로 끌어올리는 사고실험의 세계에 풍덩 빠져보시기 바랍니다.</p>
                <p>이용권을 구매하면 자동으로 유료회원이 되고, 세계에서 가장 많은 작도 문제들을 만날 수 있습니다.</p>
                <div className='horizontal-flex-container'>
                    <button 
                        className='button'
                        onClick={() => navigateto("/payment/1")}
                    >
                        이용권 구매하기
                    </button>
                    <button 
                        className='button'
                        onClick={() => navigateto(-1, {replace: true})}
                    >
                        이전화면으로 이동
                    </button>
                </div>
            </div>
        )
    } else if (errP || errFD) {
        return (
            <div className="problem">
                <p>"밑그림 파일을 다운로드하지 못했습니다. 다시 시도해 주세요."</p>
                <button 
                    className='button'
                    onClick={() => navigateto(-1, {replace: true})}
                >
                    이전화면으로 돌아가기
                </button>
            </div>
        )
    }

    return(
        <div className="problem" key={canvas.canvasCode}>
            <div className="content-fit">
                <div>
                    <input 
                        type="text" 
                        id="title"
                        style={{width:"90%", height:"38px", marginBottom:"9px"}}
                        placeholder="그림의 제목을 입력하세요."
                        value={title} 
                        onChange={onTitleHandler} 
                        required 
                    />
                </div>

                <div>
                    <input 
                        type="text" 
                        id="color"
                        style={{width:"320px", height:"38px", marginBottom:"9px"}}
                        placeholder="SpringGreen처럼 영문으로 배경색을 입력"
                        onChange={(event) => setBcolor(event.target.value)} 
                    />
                    <button 
                        className='button'
                        style={{marginLeft:"9px", marginTop: "0px", marginRight:"9px"}}
                        onClick={onBackgroundColorHandler}
                    >
                        배경색 변경
                    </button>
                    (2번 클릭하면 변경됩니다. <a href="https://www.w3schools.com/tags/ref_colornames.asp" target='blank'>색 참조</a>)
                </div>

                <div className="problemmain">
                    {canvas && <Geogebra
                        id="ggbApplet"
                        width={1100}
                        height={720}
                        // autoHeight={true}
                        // appletOnLoad={onLoad}
                        showMenuBar={false}
                        showAlgebraInput={false}
                        showToolBar={true}
                        customToolBar="0 | 62 | 40 | 41 | 42 | 6"
                        showToolBarHelp={true}
                        showResetIcon={false}
                        enableLabelDrags={true}
                        enableShiftDragZoom={false}
                        enableRightClick={false}
                        errorDialogsActive={false}
                        useBrowserForJS={false}
                        allowStyleBar={true}
                        preventFocus={false}
                        showZoomButtons={true}
                        capturingThreshold={3}
                        rounding="8"
                        // add code here to run when the applet starts
                        showFullscreenButton={true}
                        scale={1}
                        disableAutoScale={false}
                        allowUpscale={false}
                        clickToLoad={false}
                        appName="classic"
                        buttonRounding={0.7}
                        buttonShadows={false}
                        language="ko"
                        ggbBase64={`${canvas.basicSketch}`}
                    />}
                </div>
                <div className='horizontal-flex-container'>
                    {isFirst 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/geometriccoloring/${canvasOrder-1}`, {state:{
                                "canvasCategoryCode": canvasCategoryCode,
                                "canvasCategoryTitle": canvasCategoryTitle,
                                "canvasCode": prevCanvas
                            }})}
                        >
                            이전 그림판
                        </button>
                    } 
                    {isLast 
                        ? null 
                        : <button 
                            className='button' 
                            onClick={() => navigateto(`/geometriccoloring/${canvasOrder+1}`, {state:{
                                "canvasCategoryCode": canvasCategoryCode,
                                "canvasCategoryTitle": canvasCategoryTitle,
                                "canvasCode": nextCanvas
                            }})}
                        >
                            다음 그림판
                        </button>
                    }
                    <button 
                        className='button'
                        onClick={onClickHandler}
                    >
                        그림 보관하기
                    </button>
                    <button 
                        className='button'
                        onClick={
                            () => auth?.accessToken
                                ? (isMyColoring 
                                    ? navigateto(`/geometriccoloring/my/${latestColoring.answerMark}`, {state:{
                                        "canvasCategoryCode": canvasCategoryCode,
                                        "canvasCategoryTitle": canvasCategoryTitle,
                                        "myColoringList": myColoringList,
                                        // "categoryCanvasList": categoryCanvas,
                                        "canvasCode": canvasCode,
                                        "prevCanvas": prevCanvas,
                                        "nextCanvas": nextCanvas,
                                        "isFirst": isFirst,
                                        "isLast": isLast,
                                        "geoID": geoID,
                                        "isSample": isSample
                                    }})
                                    : (errMyAns
                                        ? navigateto("/login", {state:{"from": location}}, {replace: true})
                                        : alert('이 그림판에 색칠하고 저장했던 내 그림이 없습니다.')
                                    )
                                )
                                : navigateto("/login", {state:{"from": location}}, {replace: true})
                        }
                    >
                        내 그림 보기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GeometricColoring;