import React, { useEffect, useState } from 'react';
import GeoGebraMySol from '../components/GeoGebraMySol';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import '../App.css';
// import './mathsubject.css';
// import useAuth from '../hooks/useAuth';
// import GetProblemInfo from '../components/GetProblemInfo';
// import axiosapi from '../api/axiosapi';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ForTeacherMenu from '../components/ForTeacherMenu';
// import axiosPrivate from '../api/axiosPrivate';

const StudentAnswer = ()=> {
    const {studentAns}=useParams();
    // const {auth} = useAuth();
    const navigateto = useNavigate();
    const {student, studentAnswer, studentAnswers} = useLocation().state;
    // const {problem, errP} = GetProblemInfo({"problemID": studentAnswer.problemCode});
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [myConstruct, setMyConstruct] = useState('');
    const [flag, setFlag] =useState('');
    const [myAnswerRaw, setMyAnswerRaw] = useState('');
    const [myConstructionStep, setMyConstructionStep] = useState([]);
    const [myPointConstruction, setMyPointComstruction] = useState([]);
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [myAnswerPicked, setMyAnswerPicked] = useState('');

    useEffect(() => {
        setMyConstruct(myConstructionStep);
        setFlag(studentAns);
    },[myConstructionStep])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const answerPicked = studentAnswers.find((item) => item.answerMark === studentAns)
        setMyAnswerPicked(answerPicked);

        const fetchMyAnswers = async () => {

            try {
                const response = await axiosPrivate.get('/teacher/student/problemsolving', {
                    signal: controller.signal,
                    params: {
                        studentID: student.studentID,
                        problemCode: answerPicked.problemCode,
                        answerMark: studentAns
                    }
                });

                isMounted && response.data && setMyAnswerRaw(response.data);

                const myConstructStep = response.data && response.data.solvingContent.split("|");
                setMyConstructionStep(myConstructStep);

                const myPointConstruct = response.data && response.data.solvingPoint.split("|");
                setMyPointComstruction(myPointConstruct);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            } finally {
                setLoading(false);
            }
        }

        fetchMyAnswers();
        
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [studentAnswer])
    
    return(
        <div className="App-full-row-container">
            <div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
                <ForTeacherMenu />
            </div>
            <div className="content-box-1">
                <div className="vertical-flex-container" key={myAnswerRaw.answerMark}>

                    <div className="content-fit">
                        <div>
                            <select value={selectedAnswer} style={{height:"36px", width:"60%"}} onChange={(e) => setSelectedAnswer(e.target.value)}>
                                <option value>문제를 푼 때를 선택하세요.</option>
                                {studentAnswers.map(myAns => {
                                    return <option value={myAns.answerMark} key={myAns.answerMark}>
                                        {myAns.problemTitle} | {myAns.solvingTime.substring(6,10)}년 {myAns.solvingTime.substring(3,5)}월 {myAns.solvingTime.substring(0,2)}일 {myAns.solvingTime.substring(12,17)}
                                    </option>
                                })}
                            </select>
                            {console.log('selectedAnswer: ', selectedAnswer)}
                            <button 
                                className='button' 
                                style={{marginLeft:"10px"}}
                                onClick={() => navigateto(`/teacher/student/studentanswer/${selectedAnswer}`, {state: {
                                    "student": student,
                                    "studentAnswer": studentAnswer,
                                    "studentAnswers": studentAnswers
                                }})}
                            >
                                풀이 보기
                            </button>
                        </div>
                        <div key={flag}>
                            {myAnswerRaw && myConstruct.length !== 0 
                                ? <GeoGebraMySol
                                        idIn={myAnswerRaw.problemCode}
                                        customToolBarIn={myAnswerRaw.customToolBar}
                                        allowStyleBarIn={myAnswerRaw.allowStyleBar}
                                        ggbBase64In={myAnswerRaw.problemContent}
                                        myAnswerStep={myConstruct}
                                        myPointAnswer={myPointConstruction}
                                        problemType={myAnswerRaw.problemType}
                                    />
                                : "loading"
                            }
                        </div>
                        <div className='horizontal-flex-container'>
                            <button 
                                className='button'
                                onClick={() => navigateto(`/teacher/student/studentanswerlist`, {state:{
                                    "student": student
                                }})}
                            >
                                문제 풀이 목록
                            </button>
                            {(myAnswerRaw.problemType === 1 || myAnswerRaw.problemType === 2)
                                ? <button 
                                        className='button'
                                        onClick={() => navigateto(`/teacher/student/studentansweranimation/${studentAns}`, {state:{
                                            "student": student,
                                            "studentAnswer": studentAnswer,
                                            "studentAnswers": studentAnswers,
                                            "myConstructionStep": myConstructionStep,
                                            "myPointConstruction": myPointConstruction,
                                            "myAnswerRaw": myAnswerRaw
                                        }})}
                                    >
                                        풀이 과정 보기
                                    </button>
                                : null
                            }
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    );
};

export default StudentAnswer;