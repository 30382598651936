import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
// import axiosapi from "../api/axiosapi";
// import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ForTeacherMenu from "../components/ForTeacherMenu";
// import axiosPrivate from "../api/axiosPrivate";
// import "./mathsubject.css";

const ViewRegisteredStudentList = () => {
	// const {auth} = useAuth();
	const navigateto = useNavigate();
	const location = useLocation();
	const [students, setStudents] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const [selectedStudent1, setSelectedStudent1] = useState(null);
	const axiosPrivate = useAxiosPrivate();

    useEffect(()=>{
		let isMounted = true;
		const controller = new AbortController();

        const fetchRegisteredStudents = async () => {
            try{
                const response = await axiosPrivate.get(`/teacher/student/list`, {
					signal: controller.signal
				});
                isMounted && setStudents(response.data);
            } catch (err) {
                // console.log(err);
				err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

		fetchRegisteredStudents();
		return () => {
            isMounted = false;
            controller.abort();
        }
    },[])

	// const handleSelectStudent1 = (student) => {
	// 	let isMounted = true;
	// 	const controller = new AbortController();
	// 	// console.log(student);
	// 	// 선택한 학생을 서버에 등록취소를 요청
	// 	axiosPrivate.post('/teacher/student/expirebyteacher', {
	// 		studentID: student.studentID},
	// 		{
	// 			signal: controller.signal
	// 		}
	// 	).then(response => {
	// 		alert(response.data.message);
	// 	}).catch(error => {
	// 		alert("학생 등록 취소 중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.");
	// 	});
	// 	return () => {
    //         isMounted = false;
    //         controller.abort();
    //     }
	// };

	const handleSelectStudent = (student) => {
		let isMounted = true;
		const controller = new AbortController();
		// console.log(student);
		// 선택한 학생을 서버에 등록취소를 요청
		axiosPrivate.post('/teacher/student/expirebyteacher', {
			studentID: student.studentID},
			{
				signal: controller.signal
			}
		).then(response => {
			isMounted && alert(response.data.message);
		}).catch(error => {
			alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			navigateto('/login', { state: { from: location }, replace: true });
		});
		return () => {
            isMounted = false;
            controller.abort();
        }
	};

	return (
		<div className="App-full-row-container">
			<div className="left-menu-box-1" style={{fontWeight:"bolder", color:"orange" ,marginTop:"10px", marginBottom:"1px", marginLeft:"30px"}}>
				<ForTeacherMenu />
				<br/>
			</div>
			<div className="content-box-1">
				<h4 style={{ marginBottom:"10px" }}>학생 목록</h4>
				<div>
					{students && students.length ? (
						<table style={{width:"100%", maxWidth:"900px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"37px" }}>
								<tr>
									<th style={{minWidth:"100px", width:"25%"}}>이름</th>
									<th style={{minWidth:"100px", width:"25%"}}>아이디</th>
									<th style={{minWidth:"100px", width:"25%"}}>상태</th>
									<th style={{ width: '100px', textAlign: 'right' }}></th>
								</tr>
							</thead>
							<tbody>
								{students.map((student, index) => (
									<tr key={student.id} style={{ height:"37px", backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey' }}>
										<td>{student.studentName}</td>
										<td>{student.studentID}</td>
										<td>{student.studentConfirm ? "학생 확인 완료" : "학생 확인 대기 중"}</td>
										<td>
											{student.studentConfirm === true && <button className="button" style={{marginRight:"5px", marginTop:"1px", marginBottom:"1px"}} onClick={() => setSelectedStudent1(student)}>풀이 보기</button>}
											<button className="button" style={{marginTop:"1px", marginBottom:"1px"}} onClick={() => setSelectedStudent(student)}>등록 취소</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : ("등록한 학생이 없습니다.")
					}
				</div>
				{selectedStudent1 && (
					<div style={{ marginTop:"30px"}}>
						<h5 style={{ marginBottom:"10px"}}>다음 학생의 풀이를 보시겠습니까?</h5>
						<table style={{width:"100%", maxWidth:"700px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"37px" }}>
								<tr>
									<th style={{minWidth:"150px", width:"50%"}}>이름</th>
									<th style={{minWidth:"150px", width:"50%"}}>아이디</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height:"37px" }}>
									<td>{selectedStudent1.studentName}</td>
									<td>{selectedStudent1.studentID}</td>
								</tr>
							</tbody>
						</table>
						<button className="button" onClick={() => navigateto(`/teacher/student/studentanswerlist`, {state:{
									"student": selectedStudent1
								}})}>풀이 보기</button>
					</div>
				)}
				{selectedStudent && (
					<div style={{ marginTop:"30px"}}>
						<h5 style={{ marginBottom:"10px"}}>다음 학생을 정말 등록 취소하시겠습니까?</h5>
						<table style={{width:"100%", maxWidth:"700px"}}>
							<thead style={{ backgroundColor: 'yellow', height:"37px" }}>
								<tr>
									<th style={{minWidth:"150px", width:"50%"}}>이름</th>
									<th style={{minWidth:"150px", width:"50%"}}>아이디</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ height:"37px" }}>
									<td>{selectedStudent.studentName}</td>
									<td>{selectedStudent.studentID}</td>
								</tr>
							</tbody>
						</table>
						<button className="button" onClick={() => handleSelectStudent(selectedStudent)}>등록 취소</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ViewRegisteredStudentList;
