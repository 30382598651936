import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosapi from '../api/axiosapi';

const SideMenu = () => {
    const [contents, setContents] = useState([]);
    const [randomString, setRandomString] = useState();

    useEffect(()=> {
        axiosapi.get('/phase/all/')
            .then (response => {
                setContents(response.data)
            }) .catch (err => {
                console.log('사이드 메뉴 가져오기 에러:', err)
            })
    },[]);

    useEffect(() => {
        const randomAlphabetString = () => {
            const letters = '1234567890';
            let randomStringl = '';
            for (let i = 0; i < 3; i++) {
                randomStringl += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString(randomStringl); 
          };
          randomAlphabetString();
    },[contents])

    return(
        <nav>
            {contents && contents.map(content => {
                return(
                    <div key={content.id}>
                        {
                            content.id === 1 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/teacher/work/`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                <br></br>
                            </div>
                        }
                        {
                            content.id === 2 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/student/work/`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                <br></br>
                            </div>
                        }
                        {
                            content.id === 3 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/geometriccoloring`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                <br></br>
                            </div>
                        }
                        {
                            content.id === 4 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/grade/textbook/list/`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                {content.grade.map(grade => {
                                    return(
                                        <div key={grade.id}>
                                            <Link className="nav-link" 
                                                to={`/grade/${randomString}`}
                                                state={{
                                                    "phaseCode": grade.phaseCode,
                                                    "phaseTitle": grade.phaseTitle,
                                                    "phaseDescription": grade.phaseDescription
                                                }}
                                            >
                                                <p style={{margin: "0px", paddingLeft: "10px", paddingTop: "7px"}}>{grade.phaseTitle}</p>
                                            </Link>
                                        </div>
                                    )
                                })}
                                <br></br>
                            </div>
                        }
                        
                        {
                            content.id === 5 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/calculationexercise/list`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                <br></br>
                            </div>
                        }

                        {
                            content.id === 7 && <div>
                                <div className="card-2">
                                    <Link className="nav-link" to={`/grade/brainup/list/`}>
                                        <ul className='card-header'>{content.areaTypeTitle}</ul>
                                    </Link>
                                </div>
                                {content.grade.map(grade => {
                                    return(
                                        <div key={grade.id}>
                                            <Link className="nav-link" 
                                                to={`/grade/${randomString}`}
                                                state={{
                                                    "phaseCode": grade.phaseCode,
                                                    "phaseTitle": grade.phaseTitle,
                                                    "phaseDescription": grade.phaseDescription
                                                }}
                                            >
                                                <p style={{margin: "0px", paddingLeft: "10px", paddingTop: "7px"}}>{grade.phaseTitle}</p>
                                            </Link>
                                        </div>
                                    )
                                })}
                                <br></br>
                            </div>
                        }
                    </div>
                )
            })}
        </nav>
    )
}
    

export default SideMenu;
