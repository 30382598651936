import React from "react";
import { Link } from 'react-router-dom';

const ForStudentMenu = () => {
       return(
              <div>
                     <Link to={`/teacher/student/studentconfirm`}>
                            <h5>선생님 등록 확인 및 취소</h5>
                     </Link>
                     <Link to={`/student/myinfo/answer/list/`}>
                            <h5>내 풀이 보기</h5>
                     </Link>
                     <Link to={`/student/myinfo/`}>
                            <h5>내 정보</h5>
                     </Link>
                     <Link to={`/student/myinfo/update/`}>
                            <h5>회원정보 수정</h5>
                     </Link>
                     <Link to={`/student/myinfo/changepassword/`}>
                            <h5>비밀번호 변경</h5>
                     </Link>
                     <Link to={`/student/myinfo/payhistory/`}>
                            <h5>결제이력</h5>
                     </Link>
                     <Link to={`/student/work/freeclass/`}>
                            <h5>무료 화상수업 참여</h5>
                     </Link>
              </div>
       )
}

export default ForStudentMenu;