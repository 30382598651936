import { useState, useEffect } from "react";
// import axiosapi from "../api/axiosapi";
// import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ViewMyColoringItem = ({answerMark}) => {
    // const {auth} = useAuth();
    // const [count, setCount] = useState(0);
    const [errAns, setErrAns] = useState(false);
    const [loading, setLoading] = useState(true);
    const [myColoringFetched, setMyColoringFetched] = useState();
    const axiosPrivate = useAxiosPrivate();
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        setErrAns(false);

        const fetchMyColoring = async () => {

            try {
                const response = await axiosPrivate.get('/coloring/my/canvas/personal/item', {
                    params: {
                        answerMark: answerMark
                    },
                    signal: controller.signal
                });
                response.data && setMyColoringFetched(response.data);

            } catch (err) {
                err?.response?.status === 500 && setErrAns(true);
                // if (e.response) {
                //     console.log(e.response.data);
                //     console.log(e.response.status);
                //     console.log(e.response.headers);
                // } else {
                //     console.log(`Error: ${e.message}`);
                // }
            } finally {
                setLoading(false);
            }
        }

        fetchMyColoring();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [answerMark])

    return {myColoringFetched, errAns, loading}
}

export default ViewMyColoringItem;