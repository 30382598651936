import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import GeoGebra from 'react-geogebra';
// import axiosapi from "../api/axiosapi";
// import axiosimage from '../api/axiosimage';
// import '../App.css';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAxiosImagePrivate from '../hooks/useAxiosImagePrivate';

const TodayProblemModifyMyProblemDesign = () => {
    const {canvasID}=useParams();
    const {auth} = useAuth();
    const navigateto = useNavigate();
    const {todayProblemList, todayProblem, canvasList} = useLocation().state;
    const [canvasInfo, setCanvasInfo] = useState();
    const [selectedCanvas, setSelectedCanvas] = useState();
    const [flag, setFlag] = useState('');
    const [flag2, setFlag2] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');
    const [title, setTitle] = useState('');
    const [backColor, setBackColor] = useState('WHITE');
    const [defaultBackColor, setDefaultBackColor] = useState('WHITE')
    const [bcolor,setBcolor] = useState('')
    const [randomString, setRandomString] = useState();
    const formData = new FormData();
    const axiosPrivate = useAxiosPrivate();
    const axiosImagePrivate = useAxiosImagePrivate();
    const location = useLocation();

    useEffect(() => {
        setFlag(canvasID);
    },[canvasInfo])

    useEffect (() => {
        let isMounted = true;
        const controller = new AbortController();

        const getCanvasInfo = async () => {
            try {
                const response = await axiosPrivate.get(`/canvas/problem/${canvasID}`,{
                    signal: controller.signal
                })
                isMounted && response.data && setCanvasInfo(response.data);
            } catch (err) {
                err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
            }
        }

        canvasList.map((canvas) => {
            if (canvas.canvasCode === canvasID) {
                setCurrentTitle(canvas.canvasTitle);
            }
        })

        const revivalData = () => {
            const letters = '1234567890abcdefghijklmnopqrstuvwxyz';
            let randomStringl = '';
            for (let i = 0; i < 5; i++) {
                randomStringl += letters[Math.floor(Math.random() * letters.length)];
            }
            setRandomString(randomStringl); 
        };

        getCanvasInfo();
        revivalData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[canvasID])

    const onTitleHandler = (e) => {
        setTitle(e.target.value);
    }

    const onBackgroundColorHandler = () => {
        const app=window.ggbApplet;
        bcolor !== "" ? setBackColor(bcolor.toUpperCase()) : setBackColor('WHITE');
        app.evalCommand(`SetBackgroundColor(${backColor})`);
    }

    const saveMyCanvas = async () => {
        let isMounted = true;
        const controller = new AbortController();

        try {
            const response = await axiosImagePrivate.post('/canvas/create/', formData, {
                signal: controller.signal
            });
            isMounted && response.data && setFlag2(true);
            alert("이 그림을 저장했습니다.");
        } catch (err) {
            console.log(err);
            setFlag2(false);
            err?.response?.status === 500 && alert("시간 초과로 로그아웃 되었습니다. 로그인 후 다시 시도해 주세요.");
			err?.response?.status === 500 && navigateto('/login', { state: { from: location }, replace: true });
        }

        // axiosImagePrivate.post('/coloring/my/history/add/', formData)
        // .then (response => {
        //     prevTime = new Date();
        //     alert("이 그림을 저장했습니다.");
        // }) 
        // .catch(err => {
        //     console.log(err);
        //     alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.");
        // })

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    let prevTime=new Date();

    const onClickHandler = () => {
        const app=window.ggbApplet;
        let date = new Date();
        let elapsedTime = date - prevTime;
        let strUserid = auth?.accessToken? randomString : "visit";
        if (elapsedTime > 30000 && strUserid !== "visit") {
            let viewDate = date.toLocaleString('en-GB');
            let canvasCode = "qd"+viewDate.substring(0,2)+viewDate.substring(3,5)+viewDate.substring(6,10)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20)+strUserid;
            let canvasTitle = title || currentTitle;
            let canvasDescription =  + "의 풀이를 이용한 그림";
            let canvasKeyword = "#그림, #문제풀이그림"
            let orderNum = viewDate.substring(6,10)+viewDate.substring(3,5)+viewDate.substring(0,2)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20)
            let answerMark = canvasCode.substring(0,9)+viewDate.substring(0,2)+viewDate.substring(3,5)+viewDate.substring(6,10)+viewDate.substring(12,14)+viewDate.substring(15,17)+viewDate.substring(18,20);
            let myGGB = app.getBase64();
            let image = app.getPNGBase64(1,true,72);
            formData.append('canvasCode', canvasCode);
            formData.append('canvasTitle', canvasTitle);
            formData.append('canvasDescription', canvasDescription);
            formData.append('canvasKeyword', canvasKeyword);
            formData.append('customToolBar', "0 | 40 | 41 | 42");
            formData.append('showToolBarHelp', true);
            formData.append('enableLabelDrags', false);
            formData.append('enableShiftDragZoom', false);
            formData.append('allowStyleBar', true);
            formData.append('canvasType', 2);
            formData.append('canvasAdmin', 1);
            formData.append('language', "ko");
            formData.append('canvasCategoryCode', "user003");
            formData.append('orderNum', parseInt(orderNum));
            formData.append('image_data', image);
            formData.append('problemCode', todayProblem.problemCode);
            formData.append('coloringTime', viewDate);
            formData.append('answerMark', answerMark);
            formData.append('backgroundColor', backColor);
            formData.append('basicSketch', myGGB);
            saveMyCanvas();
            if (flag2) prevTime = new Date();
            // auth?.accessToken && axiosimage.post('/canvas/create/', formData, {
            //     headers: {
            //         'Authorization': `Bearer ${auth?.accessToken}`
            //     }
            // })
            //     .then (response => {
            //         prevTime = new Date();
            //         alert("이 그림을 저장했습니다.");
            //     })
            //     .catch(err => {
            //         alert("이 그림을 저장하지 못했습니다. 잠시 후 다시 시도하세요.")
            //     })
            // prevTime = new Date();
            // alert("이 그림을 저장했습니다.");
        } else alert("30초 이상 경과해야 그림을 저장할 수 있습니다.")
    }

    const onLoad = () => {
        const app = window.ggbApplet;
        let obj_no = app.getObjectNumber();
        let strName = '';
        app.evalCommand(`SetBackgroundColor(${defaultBackColor})`);
    }

    return (
        <div className="problem" key={flag}>
            <div className="content-fit">
                <h4>{currentTitle}</h4>

                <div>
                    <select value={selectedCanvas} style={{height:"36px"}} onChange={(e) => setSelectedCanvas(e.target.value)}>
                        <option value>그림 제목을 선택하세요.</option>
                        {canvasList.map(canvas => {
                            return <option value={canvas.canvasCode} key={canvas.canvasCode}>
                                {canvas.canvasTitle}
                            </option>
                        })}
                    </select>
                    <button 
                        className='button' 
                        style={{marginLeft:"10px"}}
                        onClick={() => selectedCanvas
                            ? navigateto(`/todayproblem/my/design/modify/problem/${selectedCanvas}`, {state: {
                                "todayProblemList": todayProblemList,
                                "todayProblem": todayProblem,
                                "canvasList": canvasList
                            }})
                            : alert("그림 제목을 선택하세요.")
                        }
                    >
                        다른 그림 보기
                    </button>
                </div>

                <div>
                    <input 
                        type="text" 
                        style={{width:"90%", height:"38px", marginBottom:"9px"}}
                        placeholder="새로운 그림의 제목을 입력하세요."
                        value={title} 
                        onChange={onTitleHandler} 
                        required 
                    />
                </div>

                <div>
                    <input 
                        type="text" 
                        id="color"
                        style={{width:"320px", height:"38px", marginBottom:"9px"}}
                        placeholder="SpringGreen처럼 영문으로 배경색을 입력"
                        onChange={(event) => setBcolor(event.target.value)} 
                    />
                    <button 
                        className='button'
                        style={{marginLeft:"9px", marginTop: "0px", marginRight:"9px"}}
                        onClick={onBackgroundColorHandler}
                    >
                        배경색 변경
                    </button>
                    (2번 클릭하면 변경됩니다. <a href="https://www.w3schools.com/tags/ref_colornames.asp" target='blank'>색 참조</a>)
                </div>

                <div key={flag}>
                    {canvasInfo && <GeoGebra
                        id="ggbApplet"
                        width={1100}
                        height={720}
                        // autoHeight={true}
                        appletOnLoad={onLoad}
                        showMenuBar={false}
                        showAlgebraInput={false}
                        showToolBar={true}
                        customToolBar={"0 | 62 | 1 67 5 19 | 2 15 18 7 | 4 3 8 9 13 44 | 16 51 | 10 53 11 24 20 22 21 23 | 55 56 57 12 | 30 29 54 32 31 33 | 36 38 49 | 17 | 40 | 41 | 42 | 27 | 6"}
                        showToolBarHelp={true}
                        showResetIcon={false}
                        enableLabelDrags={false}
                        enableShiftDragZoom={false}
                        enableRightClick={true}
                        errorDialogsActive={false}
                        useBrowserForJS={false}
                        allowStyleBar={true}
                        preventFocus={false}
                        showZoomButtons={false}
                        capturingThreshold={3}
                        rounding="8"
                        // add code here to run when the applet starts
                        showFullscreenButton={true}
                        scale={1}
                        disableAutoScale={false}
                        allowUpscale={false}
                        clickToLoad={false}
                        appName="classic"
                        buttonRounding={0.7}
                        buttonShadows={false}
                        language="ko"
                        ggbBase64={canvasInfo.basicSketch}
                    />
                    }
                </div>
                <div className='horizontal-flex-container'>
                    <button 
                        className='button'
                        onClick={() => navigateto(`/todayproblem/${todayProblem.urlCode}`, {state:{
                            "todayProblemList": todayProblemList,
                            "todayProblem": todayProblem
                        }})}
                    >
                        문제로 돌아가기
                    </button>

                    <button 
                        className='button'
                        onClick={onClickHandler}
                    >
                        그림 보관하기
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TodayProblemModifyMyProblemDesign;